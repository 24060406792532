export enum ControlRendererType {
    Toggle = 'Toggle',
    Text = 'Text',
    DateTime = 'DateTime',
    TextBox = 'TextBox',
    Number = 'Number',
    DomainType = 'DomainType',
    CheckBox = 'CheckBox',
    UploadImage = 'UploadImage',
    Wysiwyg = 'Wysiwyg',
    Hidden = 'Hidden',
    Calendar = 'Calendar',
    Array = 'Array',
    EnglishLevel = 'EnglishLevel',
    HoursPerWeek = 'HoursPerWeek',
}
