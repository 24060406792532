import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Sider, Heading, Hamburger } from '@kl/components-v6';
import { BaseLayoutContainer, ServicesMenu } from './styled';
import { SiderMenu, SiderUserInfo } from 'components';
import { LS_KEYS } from 'consts/storage-keys';
import { StorageService } from 'services';
import { useAuth } from 'contexts/auth.context';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import GET_USER_INFO_GQL from 'api/queries/getUserInfo';
import { UserInfo } from 'types';

const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
    const storageService = new StorageService();
    const isMenuCollapsed = storageService.getItem<boolean>(LS_KEYS['menuStatus']);
    const [menuCollapsed, setMenuCollapsed] = useState(isMenuCollapsed);
    const { isAuth } = useAuth();
    const client = useApolloClient();
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

    const { t } = useTranslation('pages/base-layout');

    const getUserInfo = async () => {
        const {
            data: {
                token: { userInfo },
            },
        } = await client.query({
            query: GET_USER_INFO_GQL,
        });
        setUserInfo(userInfo);
    };

    useEffect(() => {
        if (isAuth) {
            getUserInfo();
        }
    }, [isAuth]);

    return (
        <>
            <Sider width={280} trigger={null} collapsedWidth={64} collapsed={menuCollapsed} collapsible>
                <ServicesMenu>
                    <Hamburger
                        className="item left"
                        role="button"
                        name="hamburger"
                        onClick={() => {
                            storageService.setItem(LS_KEYS['menuStatus'], !menuCollapsed);
                            setMenuCollapsed(!menuCollapsed);
                        }}
                    />
                    <Heading color={'staticwhite'} type={'H4'}>
                        {!menuCollapsed ? t('siteTitle') : t('siteLetter')}
                    </Heading>
                </ServicesMenu>
                <SiderMenu collapsed={menuCollapsed} />
                {isAuth && userInfo && <SiderUserInfo info={userInfo} collapsed={menuCollapsed} />}
            </Sider>
            <BaseLayoutContainer>{children}</BaseLayoutContainer>
        </>
    );
};

export default BaseLayout;
