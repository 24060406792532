import React, { FC, PropsWithChildren } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useNavigate } from 'react-router-dom';
import { StorageService } from 'services/storage.service';
import { LS_KEYS } from 'consts';
import { useSettings } from './settings.context';
import { AppSettings } from 'types';
import { getSignOutUrl } from 'kl-b2c-ui-kit';

export const ApolloClientProvider: FC<PropsWithChildren> = ({ children }) => {
    const { settings } = useSettings();
    const navigate = useNavigate();

    if (!settings) {
        return null;
    }

    const storageService = new StorageService();
    const { graphQlApiAddress } = settings;

    const httpLink = createHttpLink({
        uri: graphQlApiAddress,
    });

    const authLink = setContext((_, { headers }) => {
        const token = storageService.getItem(LS_KEYS['authToken']);
        const authLinkConfig = {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
                'X-Timezone-Offset': new Date().getTimezoneOffset() * -1,
            },
        };

        return authLinkConfig;
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        const token = storageService.getItem(LS_KEYS['authToken']);
        if (networkError) {
            // eslint-disable-next-line no-console
            console.warn(`[Network error]: ${networkError.message}`);
        }

        if (!token) {
            navigate('/signin');
        }

        if (graphQLErrors) {
            for (const err of graphQLErrors) {
                if (err?.extensions?.code === 'AUTH_NOT_AUTHORIZED') {
                    const { adfsLogoutBasePath } = storageService.getItem<AppSettings>(LS_KEYS['appSettings']);
                    const signOutUrl = getSignOutUrl({
                        userKey: token as string,
                        adfsLogoutBasePath,
                    });

                    if (token) {
                        storageService.clearItem(LS_KEYS['authToken']);
                        window.location.assign(signOutUrl);
                        return;
                    }
                }
            }
        }
    });

    const client = new ApolloClient({
        cache: new InMemoryCache({
            addTypename: false,
        }),
        link: from([authLink, errorLink, httpLink]),
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
