import { Step } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const stepRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Omit<Step, 'id' | 'creationDate'>, { type: ControlRendererType; rules?: RegisterOptions }> => ({
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 50,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 50,
                }),
            },
        },
    },
    period: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 50,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 50,
                }),
            },
        },
    },
    number: {
        type: ControlRendererType.Number,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            min: {
                value: 1,
                message: t('min-val', {
                    ns: 'common/errors',
                    value: 1,
                }),
            },
        },
    },
    comment: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
});
