import { gql } from '@apollo/client';

export const GET_EDU_END_YEAR_GQL = gql`
    query ($id: UUID!) {
        internshipYears {
            finishDateById(filter: { id: $id }) {
                id
                name
                number
            }
        }
    }
`;

export default GET_EDU_END_YEAR_GQL;
