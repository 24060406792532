import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';

const SignOut: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('pages/sign-out');

    useEffect(() => {
        navigate('/');
    }, []);

    return <Loader centered size={'large'} tip={t('redirect')} />;
};

export default SignOut;
