import { gql } from '@apollo/client';

export const DELETE_INTERNSHIPREGISTRATION_GQL = gql`
    mutation ($id: UUID!) {
        internshipRegistrations {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_INTERNSHIPREGISTRATION_GQL;
