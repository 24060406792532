import React, { PropsWithChildren } from 'react';
import { Label, Key } from './styled';

interface FormRowProps {
    label: string;
    required: boolean;
}

const FormRow = (props: PropsWithChildren<FormRowProps>) => {
    const { label, children, required } = props;

    return (
        <Label>
            <Key>
                {label}
                {required && '*'}
            </Key>
            {children}
        </Label>
    );
};

export default FormRow;
