import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Heading, Loader, Select } from '@kl/components-v6';
import ADD_FA_QUESTION_GQL from 'api/mutations/addFAQuestion';
import UPDATE_FA_QUESTION_GQL from 'api/mutations/updateFAQuestion';
import GET_FA_QUESTION_GQL from 'api/queries/getFAQuestion';
import { FormBuilder } from 'containers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { useToaster } from 'contexts';
import { FaqSubject, FormBuilderKeys } from 'enums';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormRow } from 'styled/global-style';
import { FAQ as FAQModel, InternshipTeam } from 'types';
import { getByteArray } from 'utils/get-byte-array';

export const INITIAL_DATA: Omit<FAQModel, 'creationDate'> = {
    id: '',
    question: '',
    answer: '',
    number: 1,
    subject: FaqSubject.Internship,
    visible: false,
};

const FAQ: FC = () => {
    const [faq, setFAQ] = useState<Omit<FAQModel, 'creationDate'>>(INITIAL_DATA);
    const { t } = useTranslation(['pages/internships', 'common/shared']);
    const { setToaster } = useToaster();
    const navigate = useNavigate();
    const [getFAQQuery, { loading: faqLoading }] = useLazyQuery(GET_FA_QUESTION_GQL, {
        onCompleted: (response) => {
            setFAQ(response.questions.byId);
        },
    });

    const { id } = useParams();

    const [addFAQMutation, { loading: addMutationLoading }] = useMutation(ADD_FA_QUESTION_GQL);

    const [updateFAQMutation, { loading: updateMutationLoading }] = useMutation(UPDATE_FA_QUESTION_GQL);

    const methods = useForm<FAQModel>();

    const {
        control,
        formState: { errors },
    } = methods;

    const onSubmit = async (model: FieldValues) => {
        try {
            const bytes = await getByteArray(model.image as File);
            const image = model.image as File;

            await (id
                ? updateFAQMutation({
                      variables: {
                          model: {
                              ...model,
                              id: faq?.id,
                          },
                      },
                  })
                : addFAQMutation({
                      variables: {
                          model,
                      },
                      onCompleted: (response) => {
                          const {
                              questions: {
                                  add: { id },
                              },
                          } = response;
                          navigate(`/internship-faq/${id}`);
                      },
                  }));
            setToaster({
                type: 'success',
                message: t('faq-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    useEffect(() => {
        if (id) {
            getFAQQuery({ variables: { id } });
        }
    }, [id]);

    return !FAQ || faqLoading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('internship-faq')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<FAQModel, 'creationDate'>>
                    data={faq}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.FAQ}
                    loading={addMutationLoading || updateMutationLoading}
                    isFormEmpty={!id}
                    cancel={() => navigate('/internships/faqs')}
                >
                    <FormRow>
                        <span>{t('subject', { ns: 'common/shared' })}*</span>
                        <Controller
                            name={'subject'}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('required-field', {
                                        ns: 'common/errors',
                                    }),
                                },
                            }}
                            defaultValue={faq?.subject}
                            render={({ field: { onChange } }) => (
                                <Select
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={faq?.subject}
                                    options={[
                                        {
                                            label: 'Internship',
                                            value: FaqSubject.Internship,
                                        },
                                        {
                                            label: 'Selection',
                                            value: FaqSubject.Selection,
                                        },
                                    ]}
                                />
                            )}
                        />
                        {errors['subject'] && <ErrorMessage>{errors['subject'].message}</ErrorMessage>}
                    </FormRow>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default FAQ;
