import React, { FC, Key, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import DELETE_INTERNSHIPREGISTRATION_GQL from 'api/mutations/deleteInternshipRegistration';
import DOWNLOAD_INTERNSHIP_REGISTRATIONS from 'api/queries/downloadInternshipRegistrations';
import GET_INTERNSHIP_REGISTRATIONS_GQL from 'api/queries/getInternshipRegistrations';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey, SortByCreationDate } from 'enums';
import i18n from 'i18n';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import { useNavigate } from 'react-router-dom';
import { GetUsers, Users as UserModel } from 'types';
import { getFileFromBytes, getTableColumns } from 'utils';
import { renderCell } from 'utils/cell-renderer';
import { usersCellRenderMapper, usersInitialColumns } from './mappers';

const Users: FC = () => {
    const [users, setUsers] = useState<PaginationOutput<UserModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getUsersQuery] = useLazyQuery(GET_INTERNSHIP_REGISTRATIONS_GQL, {
        onCompleted: (response) => {
            const {
                internshipRegistrations: { get },
            } = response;
            setUsers(get);
        },
    });

    const [getExcelQuery] = useLazyQuery(DOWNLOAD_INTERNSHIP_REGISTRATIONS, {
        fetchPolicy: 'no-cache',
        onCompleted: (response) => {
            const {
                internshipRegistrations: {
                    excelReport: { data, fileName },
                },
            } = response;
            getFileFromBytes(data, fileName);
        },
    });

    const [deleteUsersMutation] = useMutation(DELETE_INTERNSHIPREGISTRATION_GQL, {
        refetchQueries: [GET_INTERNSHIP_REGISTRATIONS_GQL],
    });

    const getExcel = async (model: Omit<GetUsers, 'page' | 'size'>) => {
        console.log('getExcel');
        await getExcelQuery({
            variables: {
                ...model,
                sortByCreationDate:
                    model.sortByCreationDate !== SortByCreationDate.All
                        ? model.sortByCreationDate === SortByCreationDate.Yes
                        : null,
                stepicCourseId: model.stepicCourseId ? Number(model.stepicCourseId) : null,
                stepicId: model.stepicId ? Number(model.stepicId) : null,
            },
        });
    };

    const getUsers = async (model: GetUsers) => {
        await getUsersQuery({
            variables: {
                ...model,
                sortByCreationDate:
                    model.sortByCreationDate !== SortByCreationDate.All
                        ? model.sortByCreationDate === SortByCreationDate.Yes
                        : null,
                stepicCourseId: model.stepicCourseId ? Number(model.stepicCourseId) : null,
                stepicId: model.stepicId ? Number(model.stepicId) : null,
            },
        });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteUsersMutation({ variables: { id } });
    };

    return (
        <PageBuilder<UserModel>
            pageKey={PageBuilderKey.Users}
            data={users}
            getItems={(params) => getUsers(params as GetUsers)}
            getExcel={(params) => getExcel(params as Omit<GetUsers, 'page' | 'size'>)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<Omit<UserModel, 'directions'>>(
                usersInitialColumns,
                usersCellRenderMapper,
                renderCell,
                {
                    ...i18n.getResourceBundle(i18n.language, 'pages/courses'),
                    ...i18n.getResourceBundle(i18n.language, 'common/shared'),
                }
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.SortByCreationDate },
                { type: PageBuilderAdditionalFilters.DebounceNumberBox, key: FilterType.StepicCourseId },
                { type: PageBuilderAdditionalFilters.DebounceNumberBox, key: FilterType.StepicId },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.LastName },
            ]}
            scroll={{ x: 'max-content' }}
            updateItem={(speaker: UserModel) => navigate(`/user/${speaker.id}`)}
        />
    );
};

export default Users;
