import { gql } from '@apollo/client';

export const DELETE_FEEDBACK_GQL = gql`
    mutation ($id: UUID!) {
        internsFeedbacks {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_FEEDBACK_GQL;
