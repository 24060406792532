import { PageBuilderFilterType } from 'types';
import { FaqSubject, FilterType, SortByCreationDate, SortByName } from 'enums';

export type AvailableType = SortByName | FaqSubject | SortByCreationDate;

interface ReturnType {
    defaultValue: AvailableType;
    options: {
        value: AvailableType;
        label: string;
    }[];
}

export const filterToDropdown = (key: FilterType, filters: PageBuilderFilterType): ReturnType => {
    switch (key) {
        case FilterType.SortByName: {
            return {
                defaultValue: SortByName.No,
                options: Object.keys(SortByName)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: SortByName[key as keyof typeof SortByName],
                        label: key,
                    })),
            };
        }
        case FilterType.SortByCreationDate: {
            return {
                defaultValue: SortByCreationDate.All,
                options: Object.keys(SortByCreationDate)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: SortByCreationDate[key as keyof typeof SortByName],
                        label: SortByCreationDate[key as keyof typeof SortByName],
                    })),
            };
        }
        case FilterType.Subject: {
            return {
                defaultValue: FaqSubject.All,
                options: Object.keys(FaqSubject)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: FaqSubject[key as keyof typeof FaqSubject],
                        label: key,
                    })),
            };
        }
        default: {
            throw new Error(`No filter type for ${key} has been found, consider to add one`);
        }
    }
};
