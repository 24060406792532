import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts';
import { getURLParamValue } from 'kl-b2c-ui-kit';
import { Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';

const SignIn: FC = () => {
    const navigate = useNavigate();
    const { signIn } = useAuth();
    const code = getURLParamValue('code');
    const { t } = useTranslation('pages/sign-in');
    const queryParams = window.location.search;

    useEffect(() => {
        if (queryParams.includes('code')) {
            signIn(code);
            navigate('/settings');
        } else if (queryParams.includes('error')) {
            navigate('/access-denied');
        }
    }, [queryParams]);

    return <Loader centered size={'large'} tip={t('receiving')} />;
};

export default SignIn;
