export const getByteArray = (file: File): Promise<number[]> => {
    return new Promise((resolve) => {
        const fileData = new Blob([file]);
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result;
            const byteArray = new Uint8Array(arrayBuffer as ArrayBufferLike);
            const array = Array.from(byteArray);

            resolve(array);
        };
        reader.readAsArrayBuffer(fileData);
    });
};
