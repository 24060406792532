import { gql } from '@apollo/client';

export const DELETE_INTERNSHIP_STEP = gql`
    mutation ($id: UUID!) {
        phaseSelectionOfIntern {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_INTERNSHIP_STEP;
