import React, { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuContainer, MenuItem } from './styled';
import { MenuItem as IMenuItem } from 'types';
import { MENU_ITEMS } from 'consts/sider-menu';

interface SiderMenuItemProps {
    collapsed: boolean;
}

const SiderMenu: FC<PropsWithChildren<SiderMenuItemProps>> = ({ collapsed }) => (
    <MenuContainer>
        {MENU_ITEMS.map((item: IMenuItem) => (
            <MenuItem key={item.key} collapsed={collapsed}>
                <NavLink to={item.key} className={({ isActive }) => (isActive ? 'active' : '')}>
                    {item.icon}
                    <span>{item.label}</span>
                </NavLink>
            </MenuItem>
        ))}
    </MenuContainer>
);

export default SiderMenu;
