import { gql } from '@apollo/client';

export const DOWNLOAD_INTERNSHIP_REGISTRATIONS = gql`
    query ($stepicCourseId: Int, $sortByCreationDate: Boolean, $stepicId: Int, $lastName: String, $email: String) {
        internshipRegistrations {
            excelReport(
                filter: {
                    stepicCourseId: $stepicCourseId
                    sortByCreationDate: $sortByCreationDate
                    stepicId: $stepicId
                    lastName: $lastName
                    email: $email
                }
            ) {
                data
                fileName
            }
        }
    }
`;

export default DOWNLOAD_INTERNSHIP_REGISTRATIONS;
