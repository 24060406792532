import { gql } from '@apollo/client';

export const GET_AGREEMENT_GQL = gql`
    query ($id: UUID!) {
        agreements {
            byId(filter: { id: $id }) {
                id
                creationDate
                chapter
                email
                touCheckBoxIsSelected
                marketingIsSelected
                marketingCheckBoxText
                privacyPolicyIsSelected
                touCheckBoxText
                privacyPolicyCheckBoxText
                touText
                privacyPolicyText
            }
        }
    }
`;

export default GET_AGREEMENT_GQL;
