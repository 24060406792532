import { gql } from '@apollo/client';

export const DELETE_EDU_START_GQL = gql`
    mutation ($id: UUID!) {
        internshipYears {
            deleteStartDate(model: { id: $id })
        }
    }
`;

export default DELETE_EDU_START_GQL;
