import React, { FC, PropsWithChildren } from 'react';
import { Link } from '@kl/components-v6';
import { GetFile } from 'kl-b2c-ui-kit';
import { Container } from './styled';
import { Close2 } from '@kl/icons/12';

interface ImagePreviewProps {
    image: GetFile;
    width?: number;
    clear?: () => void;
}

const ImagePreview: FC<PropsWithChildren<ImagePreviewProps>> = (props) => {
    const {
        image: { fileLink, fileName },
        clear,
        width = 100,
    } = props;

    return (
        <Container style={{ width }}>
            {clear && <Close2 onClick={clear} />}
            <Link target={'__blank'} href={fileLink}>
                <img style={{ width }} src={fileLink} alt={fileName} />
            </Link>
        </Container>
    );
};

export default ImagePreview;
