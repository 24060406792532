import { gql } from '@apollo/client';

export const DELETE_DIRECTION_GQL = gql`
    mutation ($id: UUID!) {
        directions {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_DIRECTION_GQL;
