import { gql } from '@apollo/client';

export const GET_FEEDBACKS = gql`
    query ($page: Int!, $size: Int!) {
        internsFeedbacks {
            get(filter: { page: $page, size: $size, sortByCreationDate: false }) {
                count
                items {
                    id
                    creationDate
                    firstName
                    lastName
                    position
                    impression
                    feedback
                    visible
                    number
                    image {
                        fileLink
                        fileName
                    }
                }
            }
        }
    }
`;

export default GET_FEEDBACKS;
