import { gql } from '@apollo/client';

export const ADD_INTERNSHIP_STEP = gql`
    mutation ($model: AddPhaseSelectionOfInternViewModelInput!) {
        phaseSelectionOfIntern {
            add(model: $model) {
                creationDate
                id
                comment
                period
                name
                number
            }
        }
    }
`;

export default ADD_INTERNSHIP_STEP;
