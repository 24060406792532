import { gql } from '@apollo/client';

export const UPDATE_EDU_END_DATE_GQL = gql`
    mutation ($model: UpdateInternshipYearBaseViewModelInput!) {
        internshipYears {
            updateFinishDate(model: $model) {
                id
                name
                number
            }
        }
    }
`;

export default UPDATE_EDU_END_DATE_GQL;
