export const getFileFromBytes = (data: ArrayBufferLike, fileName: string, format = 'xlsx') => {
    const bytes = new Uint8Array(data);
    const blob = new Blob([bytes], { type: `application/${format}` });

    const link = document.createElement('a');
    link.style.display = 'none';
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};
