import { gql } from '@apollo/client';

export const UPDATE_EVENT_ADT_FORM_GQL = gql`
    mutation ($model: AddOrUpdateEventAdtViewModelInput!) {
        eventAdt {
            addOrUpdate(model: $model) {
                description
                link
                isVisible
                image {
                    id
                    fileLink
                }
                updateDate
            }
        }
    }
`;

export default UPDATE_EVENT_ADT_FORM_GQL;
