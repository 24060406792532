import { gql } from '@apollo/client';

export const GET_REJECTED_EMAIL_TPL_GQL = gql`
    query {
        internshipEmailTemplate {
            rejectedTemplate {
                id
                creationDate
                image {
                    id
                    fileLink
                    fileName
                }
                text
            }
        }
    }
`;

export default GET_REJECTED_EMAIL_TPL_GQL;
