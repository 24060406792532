import styled from 'styled-components';

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 12px;
    }
`;
