import { gql } from '@apollo/client';

export const DELETE_FA_QUESTION_GQL = gql`
    mutation ($id: UUID!) {
        questions {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_FA_QUESTION_GQL;
