import { ModalConfig } from 'types/core';
import { ModalType } from 'enums';

export const getModalConfig = (type: ModalType, close: () => void, action?: () => void): ModalConfig => {
    switch (type) {
        case ModalType.Markup: {
            return {
                mode: 'default',
                width: 500,
                centered: true,
                actions: {
                    FIRST_ACTION: {
                        text: 'Close',
                        onClick: close,
                    },
                },
            };
        }
        case ModalType.Confirm: {
            return {
                mode: 'default',
                width: 500,
                centered: true,
                actions: {
                    FIRST_ACTION: {
                        text: 'Delete',
                        onClick: () => {
                            if (action) {
                                action();
                            }
                            close();
                        },
                    },
                    SECOND_ACTION: {
                        text: 'Cancel',
                        onClick: close,
                    },
                },
            };
        }
    }
};
