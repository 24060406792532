import React, { FC, PropsWithChildren } from 'react';
import { Select } from '@kl/components-v6';
import { FilterType } from 'enums';
import { SelectContainer } from '../select-by-enum/styled';
import { useTranslation } from 'react-i18next';

interface SelectByArrayProps {
    withEmpty: boolean;
    filterType: FilterType;
    select: (value: string | null) => void;
    defaultValue?: string;
    options: {
        value: string | null;
        label: string;
    }[];
}

const SelectByArray: FC<PropsWithChildren<SelectByArrayProps>> = (props) => {
    const { filterType, select, defaultValue, options, withEmpty } = props;
    const { t } = useTranslation('common/shared');

    return (
        <SelectContainer>
            <span>{t(filterType)}</span>
            <Select
                defaultValue={defaultValue}
                onChange={select}
                options={withEmpty ? [{ label: t('all'), value: undefined }, ...options] : options}
            />
        </SelectContainer>
    );
};

export default SelectByArray;
