import { gql } from '@apollo/client';

export const ADD_OR_UPDATE_COURSE_REPORT_GQL = gql`
    mutation ($query: UpdateReportsViewModelInput!) {
        internshipExcel {
            addOrUpdateCoursesReport(query: $query) {
                id
                creationDate
                file {
                    id
                    fileName
                    contentType
                    fileLink
                }
            }
        }
    }
`;

export default ADD_OR_UPDATE_COURSE_REPORT_GQL;
