import React, { FC } from 'react';
import { Link } from '@kl/components-v6';
import { useModal } from 'contexts';
import { ModalType } from 'enums';
import DOMPurify from 'isomorphic-dompurify';

interface ShowMarkupProps {
    markUp: string;
}

export const ShowMarkup: FC<ShowMarkupProps> = ({ markUp }) => {
    const { setModal } = useModal();

    return (
        <Link
            onClick={() =>
                setModal(
                    ModalType.Markup,
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(markUp),
                        }}
                    />
                )
            }
        >
            Show
        </Link>
    );
};
