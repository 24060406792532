import { gql } from '@apollo/client';

export const GET_USER_INFO_GQL = gql`
    query {
        token {
            userInfo {
                fullName
                email
                userId
            }
        }
    }
`;

export default GET_USER_INFO_GQL;
