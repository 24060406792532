import React from 'react';
import { CellRenderType } from 'enums';
import { Feedback, FAQ, Step } from 'types';
import { FaqSubject } from 'enums/faq-subject';

export const stepsInitialColumns: Step = {
    id: '',
    creationDate: new Date(),
    name: '',
    number: 1,
    period: '',
    comment: '',
};

export const stepsCellRenderMapper: Record<keyof Step, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
    number: CellRenderType.TEXT,
    period: CellRenderType.TEXT,
    comment: CellRenderType.TEXT,
};

export const feedbacksInitialColumns: Feedback = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    feedback: '',
    impression: '',
    number: 1,
    position: '',
    visible: false,
    image: null,
};

export const feedbacksCellRenderMapper: Record<keyof Feedback, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    feedback: CellRenderType.TEXT,
    impression: CellRenderType.TEXT,
    number: CellRenderType.TEXT,
    position: CellRenderType.TEXT,
    visible: CellRenderType.BOOLEAN,
    image: CellRenderType.IMAGE_PREVIEW,
};

export const faqsInitialColumns: FAQ = {
    id: '',
    creationDate: new Date(),
    question: '',
    answer: '',
    number: 1,
    subject: FaqSubject.Internship,
    visible: false,
};

export const faqsCellRenderMapper: Record<keyof FAQ, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    question: CellRenderType.TEXT,
    answer: CellRenderType.TEXT,
    number: CellRenderType.TEXT,
    subject: CellRenderType.TEXT,
    visible: CellRenderType.BOOLEAN,
};
