import { gql } from '@apollo/client';

export const DELETE_DIRECTION_TYPE_GQL = gql`
    mutation ($id: UUID!) {
        directionType {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_DIRECTION_TYPE_GQL;
