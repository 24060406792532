import { gql } from '@apollo/client';

export const GET_INTERNSHIP_REGISTRATIONS_GQL = gql`
    query (
        $page: Int!
        $size: Int!
        $stepicCourseId: Int
        $sortByCreationDate: Boolean
        $stepicId: Int
        $lastName: String
        $email: String
    ) {
        internshipRegistrations {
            get(
                filter: {
                    page: $page
                    size: $size
                    stepicCourseId: $stepicCourseId
                    sortByCreationDate: $sortByCreationDate
                    stepicId: $stepicId
                    lastName: $lastName
                    email: $email
                }
            ) {
                count
                items {
                    id
                    agreementId
                    creationDate
                    stepicCourseId
                    stepicId
                    firstName
                    lastName
                    email
                    phone
                    telegramNickName
                    city
                    university
                    faculty
                    startEducation
                    finishEducation
                    startEducationStr
                    finishEducationStr
                    descriptionYourSelf
                    registrationCode
                    directionsNames
                    whatYouWantToKnowAboutInternship
                    utmTerm
                    utmMedium
                    utmCampaign
                    utmSource
                    utmContent
                    englishLevel
                    hoursPerWeek
                }
            }
        }
    }
`;

export default GET_INTERNSHIP_REGISTRATIONS_GQL;
