import { gql } from '@apollo/client';

export const GET_INTERNSHIP_REGISTRATION_FORM_GQL = gql`
    query {
        internshipSettings {
            byId: settings {
                isOpened
                interestDirectionIsRequired
                firstNameIsRequired
                lastNameIsRequired
                emailIsRequired
                phoneIsRequired
                telegramNickNameIsRequired
                cityIsRequired
                educationIsRequired
                descriptionYourSelfIsRequired
                whatYouWantToKnowAboutInternshipIsRequired
                englishLevelIsRequired
                hoursPerWeekIsRequired
                finish
                stepikCourseId
                updateDate
            }
        }
    }
`;

export default GET_INTERNSHIP_REGISTRATION_FORM_GQL;
