import { gql } from '@apollo/client';

export const ADD_INTERNSHIP_FEEDBACK = gql`
    mutation ($model: AddInternsFeedbackViewModelInput!) {
        internsFeedbacks {
            add(model: $model) {
                id
            }
        }
    }
`;

export default ADD_INTERNSHIP_FEEDBACK;
