import { gql } from '@apollo/client';

export const GET_INTERNSHIP_FEEDBACK = gql`
    query ($id: UUID!) {
        internsFeedbacks {
            byId(filter: { id: $id }) {
                id
                creationDate
                firstName
                lastName
                position
                impression
                feedback
                visible
                number
                image {
                    id
                    fileName
                    contentType
                    fileLink
                }
            }
        }
    }
`;

export default GET_INTERNSHIP_FEEDBACK;
