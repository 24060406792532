import { gql } from '@apollo/client';

export const ADD_EDU_FINISH_DATE_GQL = gql`
    mutation ($model: AddInternshipYearBaseViewModelInput!) {
        internshipYears {
            addFinishDate(model: $model) {
                id
                name
                number
            }
        }
    }
`;

export default ADD_EDU_FINISH_DATE_GQL;
