import { useLazyQuery } from '@apollo/client';
import { GET_TOKEN_GQL } from 'api/queries/getToken';
import GET_USER_INFO_GQL from 'api/queries/getUserInfo';
import { LS_KEYS } from 'consts';
import { useAuthUrls } from 'hooks';
import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { StorageService } from 'services';
import { UserInfo } from 'types';

interface AuthContext {
    signIn: (code: string | null) => void;
    signOut: () => void;
    getUserInfo: () => void;
    isAuth: boolean;
    isLoading: boolean;
    userInfo: UserInfo | null;
}

const AuthContext = createContext<AuthContext>({} as AuthContext);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = new StorageService();
    const [isAuth, setIsAuth] = useState<boolean>(!!storageService.getItem(LS_KEYS['authToken']));
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const { redirectToADFSSignIn, redirectToADFSSignOut } = useAuthUrls();

    const [getTokenQuery, { loading }] = useLazyQuery(GET_TOKEN_GQL, {
        onCompleted: (response) => {
            storageService.setItem(LS_KEYS['authToken'], response.token.token.token);
            setIsAuth(true);
        },
    });

    const [getUserInfoQuery] = useLazyQuery(GET_USER_INFO_GQL, {
        onCompleted: (response) => setUserInfo(response.token.userInfo),
    });

    const signIn = (code: string | null) => {
        if (!code) {
            redirectToADFSSignIn();
        }

        if (code) {
            getTokenQuery({ variables: { code } });
        }
    };

    const signOut = () => {
        const token = storageService.getItem(LS_KEYS['authToken']);

        if (token) {
            storageService.clearItem(LS_KEYS['authToken']);

            redirectToADFSSignOut();
            setIsAuth(false);
        }
    };

    const getUserInfo = () => {
        getUserInfoQuery();
    };

    const memoValue = useMemo(
        () => ({
            isAuth,
            isLoading: loading,
            signOut,
            signIn,
            getUserInfo,
            userInfo,
        }),
        [isAuth, loading, signOut, signIn, userInfo, getUserInfo]
    );

    return <AuthContext.Provider value={memoValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
