/* eslint-disable import/no-named-as-default-member */
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES } from 'consts';

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en',
        defaultNS: 'translation',
        load: 'languageOnly',
        fallbackLng: Object.keys(LANGUAGES)[0],
        supportedLngs: Object.keys(LANGUAGES),
        debug: process.env.NODE_ENV === 'development',
        detection: {
            order: ['queryString', 'cookie'],
            caches: ['cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
