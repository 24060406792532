import { useLazyQuery, useMutation } from '@apollo/client';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, Key, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Type as TypeModel } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { typeCellRenderMapper, typeInitialColumns } from './mappers';
import GET_DIRECTION_TYPES_GQL from 'api/queries/getDirectionTypes';
import DELETE_DIRECTION_TYPE_GQL from 'api/mutations/deleteDirectionType';

const Types: FC = () => {
    const [type, setType] = useState<PaginationOutput<TypeModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getTypeQuery] = useLazyQuery(GET_DIRECTION_TYPES_GQL, {
        onCompleted: (response) => {
            const {
                directionTypes: { get },
            } = response;
            setType(get);
        },
    });

    const [deleteDatesMutation] = useMutation(DELETE_DIRECTION_TYPE_GQL, {
        refetchQueries: [GET_DIRECTION_TYPES_GQL],
    });

    const getType = async (model: PaginationInput) => {
        await getTypeQuery({ variables: model });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteDatesMutation({ variables: { id } });
    };

    return (
        <PageBuilder<TypeModel>
            pageKey={PageBuilderKey.Types}
            data={type}
            getItems={(params) => getType(params as PaginationInput)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<TypeModel>(typeInitialColumns, typeCellRenderMapper, renderCell, {
                ...i18n.getResourceBundle(i18n.language, 'pages/directions'),
                ...i18n.getResourceBundle(i18n.language, 'common/shared'),
            })}
            addItem={() => navigate('/type')}
            updateItem={(record: TypeModel) => {
                const { id } = record;
                navigate(`/type/${id}`);
            }}
        />
    );
};

export default Types;
