import { ISettingsService } from 'interfaces';
import { AppSettings } from 'types/core';

export class SettingsService implements ISettingsService {
    getSettings = () => {
        return fetch('/settings/settings.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((settings: AppSettings) => settings)
            .catch((e: Error) => {
                console.warn(`Cannot load app settings: ${e.message}`);
                return {} as AppSettings;
            });
    };
}
