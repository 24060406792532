import styled from 'styled-components';

export const Container = styled.div`
    position: relative;

    svg {
        position: absolute;
        right: -12px;
        top: -12px;
    }
`;
