import { gql } from '@apollo/client';

export const ADD_FA_QUESTION_GQL = gql`
    mutation ($model: AddQuestionViewModelInput!) {
        questions {
            add(model: $model) {
                id
                question
                answer
                subject
                number
                visible
            }
        }
    }
`;

export default ADD_FA_QUESTION_GQL;
