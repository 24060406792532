import { gql } from '@apollo/client';

export const GET_TOKEN_GQL = gql`
    query ($code: String!) {
        token {
            token(model: { code: $code }) {
                token
            }
        }
    }
`;

export default GET_TOKEN_GQL;
