import { gql } from '@apollo/client';

export const GET_AGREEMENTS_GQL = gql`
    query ($page: Int!, $size: Int!, $email: String) {
        agreements {
            get(filter: { page: $page, size: $size, email: $email }) {
                count
                items {
                    id
                    creationDate
                    email
                    touCheckBoxIsSelected
                    marketingIsSelected
                    marketingCheckBoxText
                    privacyPolicyIsSelected
                    touCheckBoxText
                    privacyPolicyCheckBoxText
                    privacyPolicyText
                }
            }
        }
    }
`;

export default GET_AGREEMENTS_GQL;
