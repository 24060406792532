import { gql } from '@apollo/client';

export const UPDATE_LETTER_TPL_GQL = gql`
    mutation ($model: UpdateInternshipEmailTemplateViewModelInput!) {
        internshipEmailTemplate {
            update(model: $model) {
                id
            }
        }
    }
`;

export default UPDATE_LETTER_TPL_GQL;
