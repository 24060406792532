import { gql } from '@apollo/client';

export const ADD_DIRECTION_GQL = gql`
    mutation ($model: AddDirectionViewModelInput!) {
        directions {
            add(model: $model) {
                id
                name
                description
                number
                visible
                creationDate
                type {
                    id
                    name
                    creationDate
                }
                internshipTeams {
                    id
                    name
                    description
                    content
                    candidateRequirement
                    additionalRequirement
                    creationDate
                }
            }
        }
    }
`;

export default ADD_DIRECTION_GQL;
