import { gql } from '@apollo/client';

export const ADD_COURSE_GQL = gql`
    mutation ($model: AddCourseViewModelInput!) {
        courses {
            add(model: $model) {
                id
                creationDate
                stepikCourseId
                redirectLink
                name
            }
        }
    }
`;

export default ADD_COURSE_GQL;
