import React, { createContext, useContext, FC, PropsWithChildren, useMemo, useState, useEffect } from 'react';
import { Alert, Space } from '@kl/components-v6';
import { Toaster } from 'types';
import { AlertContainer } from './styled';

interface ToasterContext {
    setToaster: (toaster: Toaster) => void;
}

type ToasterInternal = Toaster & { id: string };

const ToasterContext = createContext<ToasterContext>({} as ToasterContext);

const ToasterProvider: FC<PropsWithChildren> = ({ children }) => {
    const [alerts, setAlerts] = useState<ToasterInternal[]>([]);

    const setToaster = (toaster: Toaster) =>
        setAlerts((prevState) => [{ ...toaster, id: window.crypto.randomUUID() }, ...prevState]);

    const deleteAlert = (id: string): void => {
        const cleared = alerts.filter((alert) => alert.id !== id);
        setAlerts(cleared);
    };

    const memoValue = useMemo(
        () => ({
            setToaster,
        }),
        [setToaster]
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            const copy = [...alerts];
            copy.pop();

            setAlerts(copy);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [alerts.length]);

    return (
        <ToasterContext.Provider value={memoValue}>
            {alerts.length > 0 && (
                <AlertContainer>
                    <Space direction="horizontal" size={15} style={{ marginBottom: 30 }}>
                        {alerts.map((alert: ToasterInternal) => {
                            const { type, id, message } = alert;
                            return (
                                <Alert closable key={id} mode={type} onClose={() => deleteAlert(id)}>
                                    {message}
                                </Alert>
                            );
                        })}
                    </Space>
                </AlertContainer>
            )}

            {children}
        </ToasterContext.Provider>
    );
};

export const useToaster = () => useContext(ToasterContext);

export default ToasterProvider;
