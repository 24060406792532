import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Heading, Loader } from '@kl/components-v6';
import ADD_INTERNSHIP_FEEDBACK from 'api/mutations/addFeedback';
import UPDATE_INTERNSHIP_FEEDBACK from 'api/mutations/updateFeedback';
import GET_INTERNSHIP_FEEDBACK from 'api/queries/getFeedback';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { GetFile } from 'kl-b2c-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Feedback as FeedbackModel } from 'types';
import { getByteArray } from 'utils/get-byte-array';

export const INITIAL_DATA: Omit<FeedbackModel, 'creationDate'> = {
    id: '',
    firstName: '',
    lastName: '',
    feedback: '',
    impression: '',
    number: 1,
    position: '',
    visible: false,
    image: null,
};

const Feedback: FC = () => {
    const [feedback, setFeedback] = useState<Omit<FeedbackModel, 'creationDate'>>(INITIAL_DATA);
    const { t } = useTranslation(['pages/internships', 'common/shared', 'common/error']);
    const { setToaster } = useToaster();
    const navigate = useNavigate();
    const [getFeedbackQuery, { loading: feedbackLoading }] = useLazyQuery(GET_INTERNSHIP_FEEDBACK, {
        onCompleted: (response) => {
            setFeedback(response.internsFeedbacks.byId);
        },
    });

    const { id } = useParams();

    const [addFeedbackMutation, { loading: addMutationLoading }] = useMutation(ADD_INTERNSHIP_FEEDBACK);

    const [updateFeedbackMutation, { loading: updateMutationLoading }] = useMutation(UPDATE_INTERNSHIP_FEEDBACK);

    const methods = useForm<FeedbackModel>();

    const onSubmit = async (model: FieldValues) => {
        try {
            const bytes = await getByteArray(model.image as File);
            const image = model.image as File;

            await (id
                ? updateFeedbackMutation({
                      variables: {
                          model: {
                              ...model,
                              id: feedback?.id,
                              image: (model.image as GetFile).id
                                  ? null
                                  : { contentType: image.type, data: bytes, fileName: image.name },
                          },
                      },
                  })
                : addFeedbackMutation({
                      variables: {
                          model: {
                              ...model,
                              image: (model.image as GetFile).id
                                  ? null
                                  : { contentType: image.type, data: bytes, fileName: image.name },
                          },
                      },
                      onCompleted: (response) => {
                          const {
                              internsFeedbacks: {
                                  add: { id },
                              },
                          } = response;
                          navigate(`/internship-feedback/${id}`);
                      },
                  }));
            setToaster({
                type: 'success',
                message: t('feedback-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    useEffect(() => {
        if (id) {
            getFeedbackQuery({ variables: { id } });
        }
    }, [id]);

    return !Feedback || feedbackLoading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('internship-feedback')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<FeedbackModel, 'creationDate'>>
                    data={feedback}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.Feedback}
                    loading={addMutationLoading || updateMutationLoading}
                    isFormEmpty={!id}
                    cancel={() => navigate('/internships/feedbacks')}
                />
            </FormProvider>
        </>
    );
};

export default Feedback;
