import React from 'react';
import { CellRenderType } from 'enums';
import { Years } from 'types';

export const initialColumns: Years = {
    id: '',
    name: '',
    creationDate: new Date(),
    number: 1,
};

export const cellRenderMapper: Record<keyof Years, CellRenderType> = {
    id: CellRenderType.TEXT,
    name: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    number: CellRenderType.TEXT,
};
