import styled from 'styled-components';

export const Label = styled.label`
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    position: relative;

    &:hover {
        cursor: pointer;
    }
`;
