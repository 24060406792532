import React, { FC, PropsWithChildren } from 'react';
import { Select } from '@kl/components-v6';
import { FilterType } from 'enums';
import { SelectContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { AvailableType } from 'containers/page-builder/components/filters/mappers/filter-to-dropdown';

interface SelectByEnumProps {
    filterType: FilterType;
    select: (value: string) => void;
    defaultValue: AvailableType;
    options: {
        value: AvailableType;
        label: string;
    }[];
}

const SelectByEnum: FC<PropsWithChildren<SelectByEnumProps>> = (props) => {
    const { filterType, select, defaultValue, options } = props;
    const { t } = useTranslation('common/shared');

    return (
        <SelectContainer>
            <span>{t(filterType)}</span>
            <Select defaultValue={defaultValue} onSelect={(value) => select(value as string)} options={options} />
        </SelectContainer>
    );
};

export default SelectByEnum;
