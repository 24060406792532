import { InternshipTeam } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const internshipTeamRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof Omit<InternshipTeam, 'id' | 'creationDate'>,
    { type: ControlRendererType; rules?: RegisterOptions }
> => ({
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    description: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    candidateRequirement: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    content: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    additionalRequirement: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
});
