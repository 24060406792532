import React from 'react';
import { Loader } from '@kl/components-v6';
import { CoreProvider, Router } from './containers';
import { BaseLayout } from 'components';
import { GlobalStyle as GlobalStyleV6 } from '@kl/components-v6/design-system/global-style';
import { GlobalStyle } from 'styled/global-style';
import { useSettings } from 'contexts/index';
import './i18n';

const App = () => {
    const { settings } = useSettings();

    return (
        <>
            <GlobalStyleV6 />
            <GlobalStyle />
            {settings ? (
                <CoreProvider>
                    <BaseLayout>
                        <Router />
                    </BaseLayout>
                </CoreProvider>
            ) : (
                <Loader centered size={'large'} tip={'Loading, please wait...'} />
            )}
        </>
    );
};

export default App;
