import { useLazyQuery, useMutation } from '@apollo/client';
import { PageBuilder } from 'containers';
import { FaqSubject, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, Key, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FAQ as FAQModel, GetFaqs } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { faqsCellRenderMapper, faqsInitialColumns } from './mappers';
import GET_FA_QUESTIONS_GQL from 'api/queries/getFAQuestions';
import DELETE_FA_QUESTION_GQL from 'api/mutations/deleteFAQuestion';

const FAQs: FC = () => {
    const [feedbacks, setFAQs] = useState<PaginationOutput<FAQModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getFAQsQuery] = useLazyQuery(GET_FA_QUESTIONS_GQL, {
        onCompleted: (response) => {
            const {
                questions: { get },
            } = response;
            setFAQs(get);
        },
    });

    const [deleteDatesMutation] = useMutation(DELETE_FA_QUESTION_GQL, {
        refetchQueries: [GET_FA_QUESTIONS_GQL],
    });

    const getFAQs = async (model: GetFaqs) => {
        await getFAQsQuery({
            variables: { ...model, subject: model.subject !== FaqSubject.All ? model.subject : null },
        });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteDatesMutation({ variables: { id } });
    };

    return (
        <PageBuilder<FAQModel>
            pageKey={PageBuilderKey.FAQs}
            data={feedbacks}
            getItems={(params) => getFAQs(params as GetFaqs)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<FAQModel>(faqsInitialColumns, faqsCellRenderMapper, renderCell, {
                ...i18n.getResourceBundle(i18n.language, 'pages/internships'),
                ...i18n.getResourceBundle(i18n.language, 'common/shared'),
            })}
            addItem={() => navigate('/internship-faq')}
            updateItem={(record: FAQModel) => {
                const { id } = record;
                navigate(`/internship-faq/${id}`);
            }}
            additionalFilters={[{ type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.Subject }]}
        />
    );
};

export default FAQs;
