import { gql } from '@apollo/client';

export const GET_COURSE_WITH_REPORT_GQL = gql`
    query ($id: UUID!) {
        courses {
            byId(filter: { id: $id }) {
                id
                creationDate
                stepikCourseId
                redirectLink
                name
                reports {
                    id
                    creationDate
                    file {
                        id
                        fileName
                        contentType
                        fileLink
                    }
                }
            }
        }
    }
`;

export default GET_COURSE_WITH_REPORT_GQL;
