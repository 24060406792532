import styled from 'styled-components';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';

export const ServicesMenu = styled.div`
    display: flex;
    margin: 0 16px;
    flex-direction: row;
    gap: 10px;
    color: ${colors['text-icons-elements'].primary};
    font-size: 14px;
    align-items: baseline;

    .ant-layout-sider-collapsed & {
        flex-direction: column;
        align-items: center;
    }

    .item {
        cursor: pointer;
        margin: 0.5em;
    }
`;
