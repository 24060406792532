import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Heading, Loader } from '@kl/components-v6';
import ADD_INTERNSHIP_TEAM_GQL from 'api/mutations/addInternshipTeam';
import UPDATE_INTERNSHIP_TEAM_GQL from 'api/mutations/updateInternshipTeam';
import GET_INTERNSHIP_TEAM_GQL from 'api/queries/getInternshipTeam';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { InternshipTeam as InternshipTeamModel } from 'types';

export const INITIAL_DATA: Omit<InternshipTeamModel, 'creationDate'> = {
    id: '',
    name: '',
    description: '',
    content: '',
    candidateRequirement: '',
    additionalRequirement: '',
};

const InternshipTeam: FC = () => {
    const [internshipTeam, setInternshipTeam] = useState<Omit<InternshipTeamModel, 'creationDate'>>(INITIAL_DATA);
    const { t } = useTranslation(['pages/directions', 'common/shared']);
    const { setToaster } = useToaster();
    const navigate = useNavigate();
    const [getInternshipTeamQuery, { loading: internshipTeamLoading }] = useLazyQuery(GET_INTERNSHIP_TEAM_GQL, {
        onCompleted: (response) => {
            setInternshipTeam(response.internshipTeams.byId);
        },
    });

    const { id } = useParams();

    const [addTeamMutation, { loading: addMutationLoading }] = useMutation(ADD_INTERNSHIP_TEAM_GQL);

    const [updateTeamMutation, { loading: updateMutationLoading }] = useMutation(UPDATE_INTERNSHIP_TEAM_GQL);

    const methods = useForm<InternshipTeamModel>();

    const onSubmit = async (model: FieldValues) => {
        try {
            await (id
                ? updateTeamMutation({
                      variables: {
                          model: {
                              ...model,
                              id: internshipTeam?.id,
                          },
                      },
                  })
                : addTeamMutation({
                      variables: {
                          model,
                      },
                      onCompleted: (response) => {
                          const {
                              internshipTeams: {
                                  add: { id },
                              },
                          } = response;
                          navigate(`/internship-team/${id}`);
                      },
                  }));
            setToaster({
                type: 'success',
                message: t('internship-team-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    useEffect(() => {
        if (id) {
            getInternshipTeamQuery({ variables: { id } });
        }
    }, [id]);

    return !InternshipTeam || internshipTeamLoading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('internship-team')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<InternshipTeamModel, 'creationDate'>>
                    data={internshipTeam}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.InternshipTeam}
                    loading={addMutationLoading || updateMutationLoading}
                    isFormEmpty={!id}
                    cancel={() => navigate('/directions/internship-teams')}
                />
            </FormProvider>
        </>
    );
};

export default InternshipTeam;
