import { useLazyQuery, useMutation } from '@apollo/client';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, Key, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Feedback as FeedbackModel } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { feedbacksCellRenderMapper, feedbacksInitialColumns } from './mappers';
import GET_FEEDBACKS from 'api/queries/getFeedbacks';
import DELETE_FEEDBACK_GQL from 'api/mutations/deleteFeedback';

const Feedbacks: FC = () => {
    const [feedbacks, setFeedbacks] = useState<PaginationOutput<FeedbackModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getFeedbacksQuery] = useLazyQuery(GET_FEEDBACKS, {
        onCompleted: (response) => {
            const {
                internsFeedbacks: { get },
            } = response;
            setFeedbacks(get);
        },
    });

    const [deleteDatesMutation] = useMutation(DELETE_FEEDBACK_GQL, {
        refetchQueries: [GET_FEEDBACKS],
    });

    const getFeedbacks = async (model: PaginationInput) => {
        await getFeedbacksQuery({ variables: { ...model } });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteDatesMutation({ variables: { id } });
    };

    return (
        <PageBuilder<FeedbackModel>
            pageKey={PageBuilderKey.Feedbacks}
            data={feedbacks}
            getItems={(params) => getFeedbacks(params as PaginationInput)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<FeedbackModel>(feedbacksInitialColumns, feedbacksCellRenderMapper, renderCell, {
                ...i18n.getResourceBundle(i18n.language, 'pages/internships'),
                ...i18n.getResourceBundle(i18n.language, 'common/shared'),
            })}
            addItem={() => navigate('/internship-feedback')}
            updateItem={(record: FeedbackModel) => {
                const { id } = record;
                navigate(`/internship-feedback/${id}`);
            }}
        />
    );
};

export default Feedbacks;
