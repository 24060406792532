import { Agreement } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const agreementRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Agreement, { type: ControlRendererType; rules?: RegisterOptions; readonly?: boolean }> => ({
    id: {
        type: ControlRendererType.Text,
    },
    creationDate: {
        type: ControlRendererType.DateTime,
    },
    chapter: {
        type: ControlRendererType.Text,
    },
    email: {
        type: ControlRendererType.Text,
    },
    touCheckBoxIsSelected: {
        type: ControlRendererType.CheckBox,
        readonly: true,
    },
    marketingIsSelected: {
        type: ControlRendererType.CheckBox,
        readonly: true,
    },
    marketingCheckBoxText: {
        type: ControlRendererType.Text,
    },
    privacyPolicyIsSelected: {
        type: ControlRendererType.CheckBox,
        readonly: true,
    },
    touCheckBoxText: {
        type: ControlRendererType.Text,
    },
    privacyPolicyCheckBoxText: {
        type: ControlRendererType.Text,
    },
    touText: {
        type: ControlRendererType.Text,
    },
    privacyPolicyText: {
        type: ControlRendererType.Text,
    },
});
