import { gql } from '@apollo/client';

export const UPDATE_DIRECTION_GQL = gql`
    mutation ($model: UpdateDirectionViewModelInput!) {
        directions {
            update(model: $model) {
                id
                name
                description
                number
                visible
                creationDate
                type {
                    id
                    name
                    creationDate
                }
                internshipTeams {
                    id
                    name
                    description
                    content
                    candidateRequirement
                    additionalRequirement
                    creationDate
                }
            }
        }
    }
`;

export default UPDATE_DIRECTION_GQL;
