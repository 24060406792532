import { gql } from '@apollo/client';

export const GET_DIRECTION_TYPE_GQL = gql`
    query ($id: UUID!) {
        directionTypes {
            byId(filter: { id: $id }) {
                id
                creationDate
                name
            }
        }
    }
`;

export default GET_DIRECTION_TYPE_GQL;
