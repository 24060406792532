import styled, { css } from 'styled-components';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';

export const MenuItem = styled.li<{ collapsed: boolean }>`
    a {
        padding: 10px 10px;
        margin: 0 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        transition: ease-in-out 0.2s;
        border-radius: 5px;
        color: ${colors['text-icons-elements'].primary};

        ${(props) =>
            props.collapsed &&
            css`
                justify-content: center;
                span {
                    &:last-of-type {
                        display: none;
                    }
                }
            `}

        &:hover,
        &.active {
            background: ${colors['bg'].alternative};
            cursor: pointer;
        }
    }
`;
