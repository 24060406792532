import React from 'react';
import { MenuItem } from 'types';
import { Settings, Help, Profile, Unique, ArrowTurnOver } from '@kl/icons/16';

export const MENU_ITEMS: MenuItem[] = [
    {
        icon: <Help />,
        label: 'Dictionaries',
        key: '/dictionaries/start-dates',
    },
    {
        icon: <ArrowTurnOver />,
        label: 'Directions',
        key: '/directions/all',
    },
    {
        icon: <Profile />,
        label: 'Internships',
        key: '/internships/steps',
    },
    {
        icon: <Unique />,
        label: 'Courses',
        key: '/courses/all',
    },
    {
        icon: <Settings />,
        label: 'Settings',
        key: '/settings/homepage',
    },
];
