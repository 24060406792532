import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Heading, Loader } from '@kl/components-v6';
import UPDATE_HOMEPAGE_SETTINGS_FORM_GQL from 'api/mutations/updateHomepageSettingsForm';
import GET_HOMEPAGE_SETTINGS_FORM_GQL from 'api/queries/getHomepageSettingsForm';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import React, { FC, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GlobalSiteConfig } from 'types';

const Homepage: FC = () => {
    const [config, setConfig] = useState<GlobalSiteConfig | null>(null);
    const { loading } = useQuery(GET_HOMEPAGE_SETTINGS_FORM_GQL, {
        onCompleted: (response) => {
            setConfig(response.homepageSettings.byId);
        },
    });
    const [configMutation, { loading: mutationLoading }] = useMutation(UPDATE_HOMEPAGE_SETTINGS_FORM_GQL);

    const { t } = useTranslation(['pages/config', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<Omit<GlobalSiteConfig, 'updateDate'>>();

    const onSubmit = async (data: FieldValues) => {
        try {
            const response = await configMutation({
                variables: { model: { ...data, updateDate: new Date().toISOString() } },
            });
            const {
                data: {
                    homepageSettings: { addOrUpdate },
                },
            } = response;
            setConfig(addOrUpdate);
            setToaster({
                type: 'success',
                message: t('config-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return !config || loading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('homepage')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<GlobalSiteConfig>
                    data={config}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.Config}
                    loading={mutationLoading}
                />
            </FormProvider>
        </>
    );
};

export default Homepage;
