import { useLazyQuery, useMutation } from '@apollo/client';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, Key, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Step as StepModel } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { stepsCellRenderMapper, stepsInitialColumns } from './mappers';
import GET_HIRING_STEPS_GQL from 'api/queries/getInternshipSteps';
import DELETE_INTERNSHIP_STEP from 'api/mutations/deleteInternshipStep';

const Steps: FC = () => {
    const [steps, setSteps] = useState<PaginationOutput<StepModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getStepsQuery] = useLazyQuery(GET_HIRING_STEPS_GQL, {
        onCompleted: (response) => {
            const {
                phaseSelectionOfIntern: { get },
            } = response;
            setSteps(get);
        },
    });

    const [deleteDatesMutation] = useMutation(DELETE_INTERNSHIP_STEP, {
        refetchQueries: [GET_HIRING_STEPS_GQL],
    });

    const getSteps = async (model: PaginationInput) => {
        await getStepsQuery({ variables: { ...model } });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteDatesMutation({ variables: { id } });
    };

    return (
        <PageBuilder<StepModel>
            pageKey={PageBuilderKey.Steps}
            data={steps}
            getItems={(params) => getSteps(params as PaginationInput)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<StepModel>(stepsInitialColumns, stepsCellRenderMapper, renderCell, {
                ...i18n.getResourceBundle(i18n.language, 'pages/internships'),
                ...i18n.getResourceBundle(i18n.language, 'common/shared'),
            })}
            addItem={() => navigate('/internship-step')}
            updateItem={(record: StepModel) => {
                const { id } = record;
                navigate(`/internship-step/${id}`);
            }}
        />
    );
};

export default Steps;
