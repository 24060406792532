import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Textbox } from '@kl/components-v6';
import { FilterType } from 'enums';
import { TextboxContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'kl-b2c-ui-kit';

interface DebounceNumberboxProps {
    filterType: FilterType;
    change: (value: string) => void;
    latency?: number;
}

const DebounceNumberbox: FC<PropsWithChildren<DebounceNumberboxProps>> = (props) => {
    const { filterType, change, latency = 500 } = props;
    const { t } = useTranslation('common/shared');

    const [debounce, setDebounce] = useState<string | undefined>(undefined);
    const debounceValue = useDebounce(debounce, latency);

    useEffect(() => {
        if (debounceValue !== undefined) {
            change(debounceValue);
        }
    }, [debounceValue]);

    return (
        <TextboxContainer>
            <Textbox
                type={'number'}
                allowClear
                onChange={(value: string | React.ChangeEvent<HTMLInputElement>) => setDebounce(value as string)}
                placeholder={t(filterType)}
            />
        </TextboxContainer>
    );
};

export default DebounceNumberbox;
