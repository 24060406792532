import { User } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const userRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof User, { type: ControlRendererType; rules?: RegisterOptions; readonly?: boolean }> => ({
    id: {
        type: ControlRendererType.Text,
    },
    creationDate: {
        type: ControlRendererType.DateTime,
    },
    stepicId: {
        type: ControlRendererType.Text,
    },
    stepicCourseId: {
        type: ControlRendererType.Text,
    },
    englishLevel: {
        type: ControlRendererType.EnglishLevel,
    },
    hoursPerWeek: {
        type: ControlRendererType.HoursPerWeek,
    },
    firstName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 150,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 150,
                }),
            },
        },
    },
    lastName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 150,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 150,
                }),
            },
        },
    },
    email: {
        type: ControlRendererType.Text,
    },
    phone: {
        type: ControlRendererType.Text,
    },
    telegramNickName: {
        type: ControlRendererType.Text,
    },
    city: {
        type: ControlRendererType.Text,
    },
    university: {
        type: ControlRendererType.Text,
    },
    faculty: {
        type: ControlRendererType.Text,
    },
    startEducation: {
        type: ControlRendererType.Text,
    },
    startEducationStr: {
        type: ControlRendererType.Text,
    },
    finishEducation: {
        type: ControlRendererType.Text,
    },
    finishEducationStr: {
        type: ControlRendererType.Text,
    },
    directionsNames: {
        type: ControlRendererType.Hidden,
    },
    descriptionYourSelf: {
        type: ControlRendererType.Text,
    },
    whatYouWantToKnowAboutInternship: {
        type: ControlRendererType.Text,
    },
    registrationCode: {
        type: ControlRendererType.Text,
    },
    utmTerm: {
        type: ControlRendererType.Text,
    },
    utmMedium: {
        type: ControlRendererType.Text,
    },
    utmCampaign: {
        type: ControlRendererType.Text,
    },
    utmSource: {
        type: ControlRendererType.Text,
    },
    utmContent: {
        type: ControlRendererType.Text,
    },
    agreementId: {
        type: ControlRendererType.Text,
    },
    knowAboutInternshipCity: {
        type: ControlRendererType.CheckBox,
        readonly: true,
    },
    directions: {
        type: ControlRendererType.Hidden,
    },
});
