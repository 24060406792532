import React, { createContext, useContext, FC, PropsWithChildren, useMemo, ReactNode, useState } from 'react';
import { ModalType } from 'enums';
import { Modal } from '@kl/components-v6';
import { ModalConfig } from 'types';
import { getModalConfig } from 'contexts/mappers';

interface ModalContext {
    setModal: (type: ModalType, content: ReactNode, action?: () => void) => void;
}

const ModalContext = createContext<ModalContext>({} as ModalContext);

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
    const [modalContent, setModalContent] = useState<{
        content: ReactNode;
        type: ModalType;
        config: ModalConfig;
    } | null>(null);

    const setModal = (type: ModalType, content: ReactNode, action?: () => void) =>
        setModalContent({
            content,
            type,
            config: getModalConfig(type, () => setModalContent(null), action),
        });

    const memoValue = useMemo(
        () => ({
            setModal,
        }),
        [setModal]
    );

    return (
        <ModalContext.Provider value={memoValue}>
            {!!modalContent && (
                <Modal
                    {...modalContent.config}
                    visible={!!modalContent}
                    onCancel={() => setModalContent(null)}
                    content={modalContent.content}
                />
            )}
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
