import { gql } from '@apollo/client';

export const DELETE_EDU_FINISH_GQL = gql`
    mutation ($id: UUID!) {
        internshipYears {
            deleteFinishDate(model: { id: $id })
        }
    }
`;

export default DELETE_EDU_FINISH_GQL;
