import { CellRenderType } from 'enums';
import { ReactNode } from 'react';

export const getTableColumns = <T>(
    columns: T,
    mapper: Record<keyof T, CellRenderType>,
    renderer: (value: unknown, type: CellRenderType) => ReactNode,
    resources: Record<string, string>
) =>
    Object.keys(columns as {}).map((key: string) => ({
        title: resources[key],
        key: window.crypto.randomUUID(),
        dataIndex: key,
        render: (value: unknown) => renderer(value, mapper[key as keyof T]),
    }));
