import styled from 'styled-components';

export const BaseLayoutContainer = styled.div`
    position: relative;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
`;
