import { gql } from '@apollo/client';

export const UPDATE_FA_QUESTION_GQL = gql`
    mutation ($model: UpdateQuestionViewModelInput!) {
        questions {
            update(model: $model) {
                creationDate
                id
                question
                answer
                subject
                number
                visible
            }
        }
    }
`;

export default UPDATE_FA_QUESTION_GQL;
