import React, { FC } from 'react';
import { Button, InformationCard, Icon } from '@kl/components-v6';
import { useAuthUrls } from 'hooks';
import { useTranslation } from 'react-i18next';

const NotAuthorized: FC = () => {
    const { redirectToADFSSignIn } = useAuthUrls();
    const { t } = useTranslation('pages/not-authorized');

    return (
        <InformationCard
            width={350}
            type={'horizontal'}
            title={t('title')}
            description={t('description')}
            leftSide={<Icon size={'medium'} name={'Accountcheck'} />}
        >
            <Button mode="primaryBlack" onClick={redirectToADFSSignIn} size="medium">
                {t('btnCaption')}
            </Button>
        </InformationCard>
    );
};

export default NotAuthorized;
