import styled from 'styled-components';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';

export const ErrorMessage = styled.span`
    color: ${colors['criticalitystatuses'].critical};
    position: absolute;
    bottom: -20px;
    left: 170px;
    font-size: 10px;
    font-weight: bold;
`;
