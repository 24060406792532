import { ApolloError, useMutation } from '@apollo/client';
import { Heading } from '@kl/components-v6';
import ADD_COURSE_GQL from 'api/mutations/addCourse';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import React, { FC } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Course as CourseModel } from 'types';

export const INITIAL_DATA: Omit<CourseModel, 'creationDate' | 'name'> = {
    id: '',
    stepikCourseId: undefined,
    redirectLink: '',
};

const Course: FC = () => {
    const { t } = useTranslation(['pages/courses', 'common/shared']);
    const { setToaster } = useToaster();
    const navigate = useNavigate();

    const [addCourseMutation, { loading: addMutationLoading }] = useMutation(ADD_COURSE_GQL);

    const methods = useForm<CourseModel>();

    const onSubmit = async (model: FieldValues) => {
        try {
            await addCourseMutation({
                variables: {
                    model,
                },
                onCompleted: (response) => {
                    const {
                        questions: {
                            add: { id },
                        },
                    } = response;
                    navigate('/course/all');
                },
            });
            setToaster({
                type: 'success',
                message: t('course-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return (
        <>
            <Heading type={'H2'}>{t('course')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<CourseModel, 'creationDate' | 'name'>>
                    data={INITIAL_DATA}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.Course}
                    loading={addMutationLoading}
                    isFormEmpty
                    cancel={() => navigate('/courses/all')}
                />
            </FormProvider>
        </>
    );
};

export default Course;
