import { gql } from '@apollo/client';

export const UPDATE_INTERNSHIP_FEEDBACK = gql`
    mutation ($model: UpdateInternsFeedbackViewModelInput!) {
        internsFeedbacks {
            update(model: $model) {
                id
            }
        }
    }
`;

export default UPDATE_INTERNSHIP_FEEDBACK;
