import { gql } from '@apollo/client';

export const DELETE_INTERNSHIP_TEAM_GQL = gql`
    mutation ($id: UUID!) {
        internshipTeams {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_INTERNSHIP_TEAM_GQL;
