import { EventAnnouncement } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { fileSize } from 'containers/form-builder/rules';
import { GetFile } from 'kl-b2c-ui-kit';
import { ACCEPTED_IMAGE_FORMATS, TEN_MB_IN_BYTES } from 'consts';

export const eventAnnouncementRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof Omit<EventAnnouncement, 'id' | 'creationDate'>,
    { type: ControlRendererType; rules?: RegisterOptions }
> => ({
    description: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    isVisible: {
        type: ControlRendererType.CheckBox,
    },
    link: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImage,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS, t);
            },
        },
    },
    updateDate: {
        type: ControlRendererType.DateTime,
    },
});
