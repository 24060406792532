import { useLazyQuery, useMutation } from '@apollo/client';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import { getTableColumns } from 'utils';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Agreements as AgreementModel, GetFaqs } from 'types';
import { renderCell } from 'utils/cell-renderer';
import { agreementsCellRenderMapper, agreementsInitialColumns } from './mappers';
import GET_COURSES_GQL from 'api/queries/getAgreements';

const Agreements: FC = () => {
    const [agreements, setAgreements] = useState<PaginationOutput<AgreementModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getAgreementsQuery] = useLazyQuery(GET_COURSES_GQL, {
        onCompleted: (response) => {
            const {
                agreements: { get },
            } = response;
            setAgreements(get);
        },
    });

    const getAgreements = async (model: PaginationInput) => {
        await getAgreementsQuery({
            variables: { ...model },
        });
    };

    return (
        <PageBuilder<AgreementModel>
            pageKey={PageBuilderKey.Agreements}
            data={agreements}
            getItems={(params) => getAgreements(params as GetFaqs)}
            columns={getTableColumns<Omit<AgreementModel, 'report'>>(
                agreementsInitialColumns,
                agreementsCellRenderMapper,
                renderCell,
                {
                    ...i18n.getResourceBundle(i18n.language, 'pages/courses'),
                    ...i18n.getResourceBundle(i18n.language, 'common/shared'),
                }
            )}
            additionalFilters={[{ type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email }]}
            showItem={(record: AgreementModel) => {
                const { id } = record;
                navigate(`/agreement/${id}`);
            }}
        />
    );
};

export default Agreements;
