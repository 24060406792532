import { gql } from '@apollo/client';

export const UPDATE_INTERNSHIP_TEAM_GQL = gql`
    mutation ($model: UpdateInternshipTeamViewModelInput!) {
        internshipTeams {
            update(model: $model) {
                id
                name
                description
                content
                candidateRequirement
                additionalRequirement
            }
        }
    }
`;

export default UPDATE_INTERNSHIP_TEAM_GQL;
