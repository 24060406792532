import styled from 'styled-components';

export const FormWrapper = styled.form<{ fullWidth?: boolean }>`
    padding: 20px 0;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '600px')};

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;
