import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Heading, Loader, Select } from '@kl/components-v6';
import ADD_DIRECTION_GQL from 'api/mutations/addDirection';
import UPDATE_DIRECTION_GQL from 'api/mutations/updateDirection';
import GET_DIRECTION_GQL from 'api/queries/getDirection';
import GET_DIRECTION_TYPES_GQL from 'api/queries/getDirectionTypes';
import GET_INTERNSHIP_TEAMS_GQL from 'api/queries/getInternshipTeams';
import { FormBuilder } from 'containers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { PaginationInput } from 'kl-b2c-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormRow } from 'styled/global-style';
import { Direction as DirectionModel, InternshipTeam, Type, UpdateDirection } from 'types';

const ADDITIONAL_ITEMS_FILTERS: PaginationInput = {
    page: 0,
    size: 300,
};

export const INITIAL_DATA: Omit<DirectionModel, 'creationDate' | 'internshipTeams'> = {
    id: '',
    name: '',
    number: 1,
    internshipTeamIds: [],
    type: {
        creationDate: new Date(),
        id: '',
        name: '',
    },
    typeName: '',
    visible: false,
    link: '',
    description: '',
    typeId: '',
};

const Direction: FC = () => {
    const [direction, setDirection] = useState<Omit<DirectionModel, 'creationDate' | 'internshipTeams'>>(INITIAL_DATA);
    const [types, setTypes] = useState<Type[]>([]);
    const [teams, setTeams] = useState<InternshipTeam[]>([]);
    const { t } = useTranslation(['pages/directions', 'common/shared']);
    const { setToaster } = useToaster();
    const navigate = useNavigate();

    const [getDirectionQuery, { loading: directionLoading }] = useLazyQuery(GET_DIRECTION_GQL, {
        onCompleted: (response) => {
            setDirection(response.directions.byId);
        },
    });
    const [getTypesQuery, { loading: typesLoading }] = useLazyQuery(GET_DIRECTION_TYPES_GQL, {
        onCompleted: (response) => {
            setTypes(response.directionTypes.get.items);
        },
        variables: ADDITIONAL_ITEMS_FILTERS,
    });
    const [getTeamsQuery, { loading: teamsLoading }] = useLazyQuery(GET_INTERNSHIP_TEAMS_GQL, {
        onCompleted: (response) => {
            setTeams(response.internshipTeams.get.items);
        },
        variables: { ...ADDITIONAL_ITEMS_FILTERS, sortByName: false },
    });

    const { id } = useParams();

    const [addDirectionMutation, { loading: addMutationLoading }] = useMutation(ADD_DIRECTION_GQL);

    const [updateDirectionMutation, { loading: updateMutationLoading }] = useMutation(UPDATE_DIRECTION_GQL);

    const methods = useForm<UpdateDirection>();
    const {
        control,
        formState: { errors },
    } = methods;

    const onSubmit = async (model: FieldValues) => {
        try {
            await (id
                ? updateDirectionMutation({
                      variables: {
                          model: {
                              ...model,
                              id: direction?.id,
                          },
                      },
                  })
                : addDirectionMutation({
                      variables: {
                          model,
                      },
                      onCompleted: (response) => {
                          const {
                              directions: {
                                  add: { id },
                              },
                          } = response;
                          navigate(`/direction/${id}`);
                      },
                  }));
            setToaster({
                type: 'success',
                message: t('direction-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    useEffect(() => {
        if (id) {
            getDirectionQuery({ variables: { id } });
        }
    }, [id]);

    useEffect(() => {
        getTypesQuery();
        getTeamsQuery();
    }, []);

    return !direction || directionLoading || typesLoading || teamsLoading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('direction')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<DirectionModel, 'creationDate' | 'internshipTeams'>>
                    data={direction}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.Direction}
                    loading={addMutationLoading || updateMutationLoading}
                    isFormEmpty={!id}
                    cancel={() => navigate('/directions')}
                >
                    <>
                        <FormRow>
                            <span>{t('internshipTeams', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'internshipTeamIds'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                // @ts-ignore
                                defaultValue={direction?.internshipTeamIds}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        showSearch
                                        mode={'multiple'}
                                        onChange={onChange}
                                        defaultValue={direction?.internshipTeamIds}
                                        options={teams?.map((option: InternshipTeam) => ({
                                            label: option.name,
                                            value: option.id,
                                        }))}
                                    />
                                )}
                            />
                            {errors['internshipTeamIds'] && (
                                <ErrorMessage>{errors['internshipTeamIds'].message}</ErrorMessage>
                            )}
                        </FormRow>
                        <FormRow>
                            <span>{t('type', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'typeId'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                // @ts-ignore
                                defaultValue={direction?.typeId}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        showSearch
                                        onChange={onChange}
                                        defaultValue={direction?.typeId}
                                        options={types?.map((option: Type) => ({
                                            label: option.name,
                                            value: option.id,
                                        }))}
                                    />
                                )}
                            />
                            {errors['typeId'] && <ErrorMessage>{errors['typeId'].message}</ErrorMessage>}
                        </FormRow>
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default Direction;
