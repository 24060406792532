import { ControlRendererType, FormBuilderKeys } from 'enums';
import { omit } from 'kl-b2c-ui-kit';
import { RegisterOptions } from 'react-hook-form';
import {
    AddDates,
    Agreement,
    AppliedEmail,
    ControlRendererConfig,
    Course,
    Direction,
    EventAnnouncement,
    FAQ,
    Feedback,
    GlobalSiteConfig,
    InternshipTeam,
    RegistrationForm,
    RejectedEmail,
    Step,
    Type,
    User,
} from 'types';
import {
    addDatesRendererTypes,
    configRendererTypes,
    directionRendererTypes,
    eventAnnouncementRendererTypes,
    faqRendererTypes,
    feedbackRendererTypes,
    internshipTeamRendererTypes,
    registrationFormTypes,
    rejectedEmailRendererTypes,
    stepRendererTypes,
    typeRendererTypes,
    courseRendererTypes,
    userRendererTypes,
    agreementRendererTypes,
} from './control-configs';

// These are available types for pair TYPE: CONTROL_TYPE for example if you want to add form for Speakers
// you add | Speaker here.
type AvailableTypes = GlobalSiteConfig | EventAnnouncement | RegistrationForm | RejectedEmail | AddDates;

// This is mapper that get controls types by ExcludedControlType, for example Config and set it to object with solid
// typings, in Config it is Record<keyof AppConfig, ControlRendererType>, so in imported configRendererTypes you cannot
// add properties that are not in AppConfig
const controlTypes = (
    key: FormBuilderKeys,
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof AvailableTypes, { type: ControlRendererType; rules?: RegisterOptions; readonly?: boolean }> => {
    const types = {
        Config: configRendererTypes(t),
        EventAnnouncement: eventAnnouncementRendererTypes(t),
        RejectedEmail: rejectedEmailRendererTypes(t),
        AppliedEmail: rejectedEmailRendererTypes(t),
        RegistrationForm: registrationFormTypes(t),
        AddDates: addDatesRendererTypes(t),
        InternshipTeam: internshipTeamRendererTypes(t),
        Type: typeRendererTypes(t),
        Direction: directionRendererTypes(t),
        Step: stepRendererTypes(t),
        Feedback: feedbackRendererTypes(t),
        FAQ: faqRendererTypes(t),
        Course: courseRendererTypes(t),
        User: userRendererTypes(t),
        Agreement: agreementRendererTypes(t),
    };

    return types[key];
};

export const getControlRenderer = <T>(
    formKey: FormBuilderKeys,
    data: T,
    t: (key: string, options?: Record<string, number | string>) => string
) => {
    switch (formKey) {
        case FormBuilderKeys.Config: {
            return getConfigPageForm<GlobalSiteConfig>(data as GlobalSiteConfig, formKey, t);
        }
        case FormBuilderKeys.EventAnnouncement: {
            return getConfigPageForm<EventAnnouncement>(data as EventAnnouncement, formKey, t);
        }
        case FormBuilderKeys.AppliedEmail:
        case FormBuilderKeys.RejectedEmail: {
            return getConfigPageForm(omit<AppliedEmail>(['id'], data as AppliedEmail), formKey, t);
        }

        case FormBuilderKeys.RegistrationForm: {
            return getConfigPageForm(data as RegistrationForm, formKey, t);
        }

        case FormBuilderKeys.AddDates: {
            return getConfigPageForm(omit<AddDates>(['id', 'creationDate'], data as AddDates), formKey, t);
        }

        case FormBuilderKeys.InternshipTeam: {
            return getConfigPageForm(omit<InternshipTeam>(['id', 'creationDate'], data as InternshipTeam), formKey, t);
        }

        case FormBuilderKeys.Type: {
            return getConfigPageForm(omit<Type>(['id', 'creationDate'], data as Type), formKey, t);
        }

        case FormBuilderKeys.Direction: {
            return getConfigPageForm(
                omit<Direction>(['id', 'creationDate', 'typeId', 'internshipTeamIds'], data as Direction),
                formKey,
                t
            );
        }

        case FormBuilderKeys.Step: {
            return getConfigPageForm(omit<Step>(['id', 'creationDate'], data as Step), formKey, t);
        }

        case FormBuilderKeys.Feedback: {
            return getConfigPageForm(omit<Feedback>(['id', 'creationDate'], data as Feedback), formKey, t);
        }

        case FormBuilderKeys.FAQ: {
            return getConfigPageForm(omit<FAQ>(['id', 'creationDate'], data as FAQ), formKey, t);
        }

        case FormBuilderKeys.Course: {
            return getConfigPageForm(
                omit<Course>(['id', 'name', 'report', 'creationDate'], data as Course),
                formKey,
                t
            );
        }

        case FormBuilderKeys.User: {
            return getConfigPageForm(omit(['directionsId', 'directions'], data as User), formKey, t);
        }

        case FormBuilderKeys.Agreement: {
            return getConfigPageForm(data as Agreement, formKey, t);
        }

        default: {
            throw new Error(`There is no control renderer for ${formKey}, consider to add one`);
        }
    }
};

const getConfigPageForm = <T>(
    data: T,
    formKey: FormBuilderKeys,
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof T, ControlRendererConfig> => {
    let result = {} as Record<keyof T, ControlRendererConfig>;
    const controlType = controlTypes(formKey as FormBuilderKeys, t);

    Object.keys(data as keyof AvailableTypes).forEach((key: string) => {
        if (!controlType[key as keyof AvailableTypes]) {
            throw new Error(`Please omit<> this property ${key} from this control ${controlType}`);
        }
        const { type, rules, readonly } = controlType[key as keyof AvailableTypes];

        result = {
            ...result,
            [key]: {
                controlValue: data[key as keyof T],
                config: {
                    type,
                    rules,
                    readonly,
                },
                key,
            },
        };
    });

    return result;
};
