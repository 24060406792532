import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useFormContext, FieldValues, Controller } from 'react-hook-form';
import { Footer, FormRow } from './components';
import { ImagePreview, ImageUploader } from 'components';
import { getControlRenderer, enumsToOptions } from './mappers';
import { ControlRendererType, EnglishLevel, FormBuilderKeys, HoursPerWeek } from 'enums';
import { ControlRendererConfig } from 'types';
import { FormWrapper, ErrorMessage } from './styled';
import { useTranslation } from 'react-i18next';
import { Select, Textbox, Toggle, Checkbox, Calendar, Icon } from '@kl/components-v6';
import { GetFile } from 'kl-b2c-ui-kit';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { DATE_FORMAT, ENGLISH_LEVEL_MAPPER, HOURS_PER_WEEK_MAPPER } from 'consts';
import DOMPurify from 'isomorphic-dompurify';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';

interface FormBuilderProps<TData> {
    data: TData;
    submit?: (params: FieldValues) => Promise<void>;
    formKey: FormBuilderKeys;
    loading: boolean;
    cancel?: () => void;
    isFormEmpty?: boolean;
    fullWidth?: boolean;
}

const FormBuilder = <TData,>(props: PropsWithChildren<FormBuilderProps<TData>>) => {
    const [config, setConfig] = useState<Record<string, ControlRendererConfig> | null>(null);
    const { data, submit, formKey, cancel, isFormEmpty, loading, children, fullWidth = false } = props;
    const { t } = useTranslation(['pages/form-builder', 'common/shared', 'common/errors']);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        if (data) {
            setConfig(getControlRenderer<TData>(formKey, data, t));
        }
    }, [data]);

    if (!config) {
        return null;
    }

    return (
        <FormWrapper className={loading ? 'disabled' : ''} fullWidth={fullWidth}>
            {Object.values(config).map((value: ControlRendererConfig) => {
                const {
                    config: { type, rules, readonly },
                    controlValue,
                    key,
                } = value;

                const error = errors[key]?.message as string;

                if (type === ControlRendererType.Hidden) return null;

                return (
                    <FormRow key={key} label={t(key, { ns: 'common/shared' })} required={!!rules?.required}>
                        {(() => {
                            switch (type) {
                                case ControlRendererType.Toggle:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => (
                                                <Toggle
                                                    checked={value !== undefined ? value : controlValue}
                                                    onChange={(val: boolean) => onChange(val)}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.DateTime:
                                    return <span>{new Date(controlValue as Date).toLocaleString('ru')}</span>;
                                case ControlRendererType.Array:
                                    return <span>{(controlValue as unknown[]).join(', ')}</span>;
                                case ControlRendererType.HoursPerWeek:
                                    return <span>{HOURS_PER_WEEK_MAPPER[controlValue as HoursPerWeek]}</span>;
                                case ControlRendererType.EnglishLevel:
                                    return <span>{ENGLISH_LEVEL_MAPPER[controlValue as EnglishLevel]}</span>;
                                case ControlRendererType.Text:
                                    return (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(controlValue as string),
                                            }}
                                        />
                                    );
                                case ControlRendererType.CheckBox:
                                    if (readonly) {
                                        const config = {
                                            name: controlValue ? 'Check' : 'Minus',
                                            color: controlValue
                                                ? colors['criticalitystatuses'].positive
                                                : colors['criticalitystatuses'].critical,
                                        };

                                        return (
                                            <Icon
                                                key={window.crypto.randomUUID()}
                                                size={'small'}
                                                // @ts-ignore
                                                name={config.name}
                                                color={config.color}
                                            />
                                        );
                                    }

                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            disabled={readonly}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox defaultChecked={value} onChange={onChange} />
                                            )}
                                        />
                                    );
                                case ControlRendererType.Number:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue || ''}
                                            render={({ field: { onChange, value } }) => (
                                                <Textbox
                                                    type={'number'}
                                                    value={value}
                                                    invalid={!!errors[key]?.message}
                                                    allowClear
                                                    onChange={(val) => onChange(Number(val))}
                                                    placeholder={t(key, { ns: 'common/shared' })}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.TextBox:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue || ''}
                                            render={({ field: { onChange, value } }) => (
                                                <Textbox
                                                    value={value}
                                                    invalid={!!errors[key]?.message}
                                                    allowClear
                                                    onChange={onChange}
                                                    placeholder={t(key, { ns: 'common/shared' })}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.UploadImage: {
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => {
                                                if (readonly && !(value as GetFile)?.id) {
                                                    return <span>File has not been provided</span>;
                                                }

                                                if (readonly && (value as GetFile)?.id) {
                                                    return <a href={value.fileLink}>{value.fileName}</a>;
                                                }

                                                if ((value as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => onChange(null)}
                                                            width={200}
                                                            image={controlValue as GetFile}
                                                        />
                                                    );
                                                }

                                                return <ImageUploader change={onChange} />;
                                            }}
                                        />
                                    );
                                }
                                case ControlRendererType.DomainType:
                                    const options = enumsToOptions(type);

                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange } }) => (
                                                <Select
                                                    defaultValue={controlValue as string}
                                                    onSelect={(value) => onChange(value as string)}
                                                    options={options}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.Calendar:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue ? new Date(controlValue as string) : null}
                                            render={({ field: { onChange, value } }) => (
                                                <Calendar
                                                    value={value}
                                                    style={{ width: 150 }}
                                                    placeholder={t('choose-date', { ns: 'common/shared' })}
                                                    format={DATE_FORMAT}
                                                    allowClear
                                                    onChange={(value: Date | null) => onChange(value)}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.Wysiwyg:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <DefaultEditor
                                                        containerProps={{ style: { width: '100%' } }}
                                                        onClick={(e) => {
                                                            // Strange behaviour for cursor, so I have to prevent it
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        value={value}
                                                        onChange={(event) => onChange(event.target.value)}
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                default:
                                    throw new Error(
                                        `There is no control type for ${type}, consider to add one in to switch`
                                    );
                            }
                        })()}
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                    </FormRow>
                );
            })}

            {children}

            <Footer
                submit={submit ? handleSubmit(submit) : undefined}
                cancel={cancel || reset}
                res={{
                    submitBtnText: isFormEmpty ? t('addBtnText') : t('updateBtnText'),
                    cancelBtnText: t('cancelBtnText'),
                }}
                loading={loading}
            />
        </FormWrapper>
    );
};

export default FormBuilder;
