import { gql } from '@apollo/client';

export const UPDATE_EDU_START_DATE_GQL = gql`
    mutation ($model: UpdateInternshipYearBaseViewModelInput!) {
        internshipYears {
            updateStartDate(model: $model) {
                id
                name
                number
            }
        }
    }
`;

export default UPDATE_EDU_START_DATE_GQL;
