import { gql } from '@apollo/client';

export const UPDATE_INTERNSHIP_STEP = gql`
    mutation ($model: UpdatePhaseSelectionOfInternViewModelInput!) {
        phaseSelectionOfIntern {
            update(model: $model) {
                creationDate
                id
                comment
                period
                name
                number
            }
        }
    }
`;

export default UPDATE_INTERNSHIP_STEP;
