import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { RegistrationForm } from 'types';

export const registrationFormTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof RegistrationForm, { type: ControlRendererType; rules?: RegisterOptions; readonly?: boolean }> => ({
    cityIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    descriptionYourSelfIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    educationIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    emailIsRequired: {
        type: ControlRendererType.CheckBox,
        readonly: true,
    },
    firstNameIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    interestDirectionIsRequired: {
        type: ControlRendererType.CheckBox,
        readonly: true,
    },
    isOpened: {
        type: ControlRendererType.CheckBox,
    },
    lastNameIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    phoneIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    telegramNickNameIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    whatYouWantToKnowAboutInternshipIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    englishLevelIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    hoursPerWeekIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    finish: {
        type: ControlRendererType.Calendar,
        rules: {
            required: {
                value: true,
                message: t('required-calendar', {
                    ns: 'common/errors',
                }),
            },
            validate: (date: Date) => {
                const isPastDay = new Date(date.toDateString()) < new Date(new Date().toDateString());

                if (isPastDay) {
                    return t('past-date', { ns: 'common/errors' });
                }

                return true;
            },
        },
    },
    stepikCourseId: {
        type: ControlRendererType.Hidden,
    },
    updateDate: {
        type: ControlRendererType.DateTime,
    },
});
