import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Heading, Loader } from '@kl/components-v6';
import UPDATE_EVENT_ADT_FORM_GQL from 'api/mutations/updateEventAdtForm';
import GET_EVENT_ADT_FORM_GQL from 'api/queries/getEventAdtForm';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { GetFile } from 'kl-b2c-ui-kit';
import React, { FC, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventAnnouncement as EventAnnouncementModel } from 'types';
import { getByteArray } from 'utils/get-byte-array';

const EventAnnouncement: FC = () => {
    const [eventAnnouncement, setEventAnnouncement] = useState<EventAnnouncementModel | null>(null);
    const { loading } = useQuery(GET_EVENT_ADT_FORM_GQL, {
        onCompleted: (response) => {
            setEventAnnouncement(response.eventAdt.byId);
        },
    });
    const [eventAnnouncementMutation, { loading: mutationLoading }] = useMutation(UPDATE_EVENT_ADT_FORM_GQL);
    const { t } = useTranslation(['pages/config', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<Omit<EventAnnouncementModel, 'updateDate'>>();

    const onSubmit = async (model: FieldValues) => {
        try {
            const bytes = await getByteArray(model.image as File);
            const image = model.image as File;

            const response = await eventAnnouncementMutation({
                variables: {
                    model: {
                        ...model,
                        image: (model.image as GetFile).id
                            ? null
                            : { contentType: image.type, data: bytes, fileName: image.name },
                        updateDate: new Date().toISOString(),
                    },
                },
            });
            const {
                data: {
                    eventAdt: { addOrUpdate },
                },
            } = response;
            setEventAnnouncement(addOrUpdate);
            setToaster({
                type: 'success',
                message: t('event-announcement-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return !eventAnnouncement || loading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('event-announcement')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<EventAnnouncementModel>
                    data={eventAnnouncement}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.EventAnnouncement}
                    loading={mutationLoading}
                />
            </FormProvider>
        </>
    );
};

export default EventAnnouncement;
