import React, { FC, PropsWithChildren, useState } from 'react';
import { Button, Space } from '@kl/components-v6';
import { Download } from '@kl/icons/16';
import { FilterType, PageBuilderAdditionalFilters } from 'enums';
import { PageBuilderFilterType } from 'types';
import { useTranslation } from 'react-i18next';
import { filterToDropdown } from './mappers';
import { DebounceNumberbox, DebounceTextbox, SelectByArray, SelectByEnum } from 'components';

interface FiltersProps {
    setFilter: (newFilter: Record<string, string | number | undefined | null>) => void;
    disableExcelDownload: boolean;
    downloadExcel?: () => Promise<void>;
    additionalFilters?: {
        type: PageBuilderAdditionalFilters;
        key: FilterType;
        withEmpty?: boolean;
        defaultValue?: string;
        items?: {
            value: string | null;
            label: string;
        }[];
    }[];
    filters: PageBuilderFilterType;
}

export const Filters: FC<PropsWithChildren<FiltersProps>> = ({
    setFilter,
    disableExcelDownload,
    downloadExcel,
    additionalFilters,
    filters,
}) => {
    const [excelLoading, setExcelLoading] = useState<boolean>(false);
    const { t } = useTranslation('common/shared');

    return (
        <Space direction="horizontal" size={15} style={{ marginBottom: 30, marginTop: 30, display: 'flex' }}>
            {additionalFilters?.length &&
                additionalFilters.map(
                    (filter: {
                        type: PageBuilderAdditionalFilters;
                        key: FilterType;
                        defaultValue?: string;
                        items?: {
                            value: string | null;
                            label: string;
                        }[];
                    }) => {
                        const { type, key } = filter;

                        switch (type) {
                            case PageBuilderAdditionalFilters.Dropdown:
                                const { options, defaultValue } = filterToDropdown(key, filters);

                                return (
                                    <SelectByEnum
                                        key={key}
                                        filterType={key}
                                        defaultValue={defaultValue}
                                        select={(value: string) => setFilter({ [key]: value as string, page: 0 })}
                                        options={options}
                                    />
                                );
                            case PageBuilderAdditionalFilters.DropdownWithSearch:
                                return (
                                    <SelectByArray
                                        key={key}
                                        filterType={key}
                                        defaultValue={filter.defaultValue}
                                        withEmpty
                                        select={(val: string | null) => setFilter({ [key]: val as string })}
                                        options={filter.items as []}
                                    />
                                );
                            case PageBuilderAdditionalFilters.DebounceTextBox:
                                return (
                                    <DebounceTextbox
                                        key={key}
                                        filterType={key}
                                        change={(value) => setFilter({ [key]: value, page: 0 })}
                                    />
                                );
                            case PageBuilderAdditionalFilters.DebounceNumberBox:
                                return (
                                    <DebounceNumberbox
                                        key={key}
                                        filterType={key}
                                        change={(value) => setFilter({ [key]: value, page: 0 })}
                                    />
                                );
                        }
                    }
                )}

            {downloadExcel && (
                <Button
                    loading={excelLoading}
                    disabled={disableExcelDownload}
                    iconAfter={<Download key={'downloadIcon'} color={'#fff'} />}
                    mode="primaryBlack"
                    onClick={async () => {
                        setExcelLoading(true);
                        await downloadExcel();
                        setExcelLoading(false);
                    }}
                >
                    {t('xlsDownload')}
                </Button>
            )}
        </Space>
    );
};
