import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, Link } from '@kl/components-v6';
import { GetFile, toDateTime } from 'kl-b2c-ui-kit';
import { CellRenderType, EnglishLevel, HoursPerWeek } from 'enums';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';
import { ImagePreview, ShowMarkup } from 'components';
import { Text } from 'styled/global-style';
import DOMPurify from 'isomorphic-dompurify';
import { ENGLISH_LEVEL_MAPPER, HOURS_PER_WEEK_MAPPER } from 'consts';

export const renderCell = (value: unknown, type: CellRenderType) => {
    switch (type) {
        case CellRenderType.BOOLEAN:
            const config = {
                name: value ? 'Check' : 'Minus',
                color: value ? colors['criticalitystatuses'].positive : colors['criticalitystatuses'].critical,
            };
            // @ts-ignore
            return <Icon key={window.crypto.randomUUID()} size={'small'} name={config.name} color={config.color} />;
        case CellRenderType.DATETIME:
            return <Text>{toDateTime(value as string)}</Text>;
        case CellRenderType.MARKUP:
            if (!value) return null;
            return <ShowMarkup markUp={value as string} />;
        case CellRenderType.FILE:
            if (!value) {
                return <span>File has not been provided</span>;
            }

            const { fileName, fileLink } = value as GetFile;
            return (
                <Link href={fileLink} target="__blank">
                    {fileName}
                </Link>
            );
        case CellRenderType.AGREEMENT_ID:
            return <RouterLink to={`/agreement/${value as string}`}>{value as string}</RouterLink>;
        case CellRenderType.IMAGE_PREVIEW:
            const file = value as GetFile;

            if (!file) {
                return <Text>No image has been provided</Text>;
            }

            return <ImagePreview image={file} />;
        case CellRenderType.ARRAY_OF_VALUES:
            if (!(value as unknown[]).length) {
                return null;
            }

            return (
                <Text
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize((value as unknown[]).join(', ')),
                    }}
                />
            );
        case CellRenderType.ENGLISH_LEVEL:
            return <Text>{ENGLISH_LEVEL_MAPPER[value as EnglishLevel]}</Text>;
        case CellRenderType.HOURS_PER_WEEK:
            return <Text>{HOURS_PER_WEEK_MAPPER[value as HoursPerWeek]}</Text>;

        default:
            return (
                <Text
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(value as string),
                    }}
                />
            );
    }
};
