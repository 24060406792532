import styled from 'styled-components';

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .ant-select {
        min-width: 100px;
    }

    span {
        &:first-child {
            white-space: nowrap;
        }
    }
`;
