import { gql } from '@apollo/client';

export const GET_INTERNSHIP_STEP = gql`
    query ($id: UUID!) {
        phaseSelectionOfIntern {
            byId(filter: { id: $id }) {
                creationDate
                id
                comment
                period
                name
                number
            }
        }
    }
`;

export default GET_INTERNSHIP_STEP;
