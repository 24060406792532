import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Heading, Loader, Space, Table } from '@kl/components-v6';
import GET_COURSE_WITH_REPORT_GQL from 'api/queries/getCourseWithReport';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FileData, Report as ReportModel } from 'types';
import { Upload, Cross } from '@kl/icons/16';
import { HiddenInput } from 'components/image-uploader/styled';
import ADD_OR_UPDATE_COURSE_REPORT_GQL from 'api/mutations/addOrUpdateReports';
import { getByteArray } from 'utils';
import { useToaster } from 'contexts/toaster.context';
import { GetFile } from 'kl-b2c-ui-kit';

const COLUMNS = [
    { key: 'id', dataIndex: 'id', title: 'Id' },
    { key: 'creationDate', dataIndex: 'creationDate', title: 'Creation Date' },
    {
        key: 'file',
        dataIndex: 'file',
        title: 'File',
        render: (report: GetFile) => (
            <a href={report?.fileLink} download>
                Download
            </a>
        ),
    },
];

const Reports: FC = () => {
    const [data, setData] = useState<{ name: string; reports: ReportModel[] } | null>({
        name: '',
        reports: [],
    });
    const [file, setFile] = useState<FileData | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const { id } = useParams();
    const { t } = useTranslation(['pages/courses', 'common/shared', 'common/errors']);
    const { setToaster } = useToaster();

    const [getReportsQuery, { loading: reportsLoading }] = useLazyQuery(GET_COURSE_WITH_REPORT_GQL, {
        onCompleted: (response) => {
            const {
                courses: {
                    byId: { name, reports },
                },
            } = response;
            setData({ name, reports });
        },
    });

    const [addReportMutation, { loading: addReportMutationLoading }] = useMutation(ADD_OR_UPDATE_COURSE_REPORT_GQL, {
        refetchQueries: [GET_COURSE_WITH_REPORT_GQL],
    });

    const getReports = async (model: { id: string }) => {
        await getReportsQuery({
            variables: { ...model },
        });
    };

    const uploadReport = async () => {
        try {
            await addReportMutation({
                variables: {
                    query: {
                        courseId: id || '',
                        excelFile: file?.data,
                    },
                },
            });
            setToaster({
                type: 'success',
                message: t('report-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    useEffect(() => {
        if (id) {
            getReports({ id });
        }
    }, [id]);

    return !data ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading style={{ marginBottom: 30 }} type={'H2'}>
                {data.name}
            </Heading>
            <Space direction="horizontal" size={15} style={{ marginBottom: 30, display: 'flex' }}>
                <Button
                    mode={'secondary'}
                    onClick={() => {
                        if (!file) {
                            return inputRef.current?.click();
                        }

                        return setFile(null);
                    }}
                    iconAfter={file ? <Cross /> : <Upload />}
                    loading={addReportMutationLoading}
                >
                    {file ? file.name : t('choose-report')}
                </Button>
                <Button disabled={!file} mode={'primary'} onClick={uploadReport} loading={addReportMutationLoading}>
                    {t('upload-report')}
                </Button>
                {!file && (
                    <HiddenInput
                        ref={inputRef}
                        type={'file'}
                        accept={'.xlsx, .xls'}
                        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                            const files = (event.target as HTMLInputElement).files;
                            if (files && files[0]) {
                                const bytes = await getByteArray(files[0]);
                                setFile({ ...files[0], name: files[0].name, data: bytes });
                            }
                        }}
                    />
                )}
            </Space>
            {data?.reports.length === 0 ? (
                <span>{t('empty-reports')}</span>
            ) : (
                <Table
                    loading={reportsLoading}
                    pagination={false}
                    dataSource={
                        data?.reports.map((item: ReportModel) => ({
                            ...item,
                            key: item.id,
                        })) || []
                    }
                    columns={COLUMNS}
                />
            )}
        </>
    );
};

export default Reports;
