import { useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_DIRECTION_GQL } from 'api/mutations/deleteDirection';
import { GET_DIRECTIONS_GQL } from 'api/queries/getDirections';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, Key, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Directions as DirectionModel } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { directionsCellRenderMapper, directionsInitialColumns } from './mappers';

const Directions: FC = () => {
    const [directions, setDirections] = useState<PaginationOutput<DirectionModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getDirectionsQuery] = useLazyQuery(GET_DIRECTIONS_GQL, {
        onCompleted: (response) => {
            const {
                directions: { get },
            } = response;
            setDirections(get);
        },
    });

    const [deleteDatesMutation] = useMutation(DELETE_DIRECTION_GQL, {
        refetchQueries: [GET_DIRECTIONS_GQL],
    });

    const getDirections = async (model: PaginationInput) => {
        await getDirectionsQuery({ variables: { ...model } });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteDatesMutation({ variables: { id } });
    };

    return (
        <PageBuilder<Omit<DirectionModel, 'internshipTeams' | 'typeId' | 'type' | 'internshipTeamIds'>>
            pageKey={PageBuilderKey.Directions}
            data={directions}
            getItems={(params) => getDirections(params as PaginationInput)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<Omit<DirectionModel, 'internshipTeams' | 'typeId' | 'type' | 'internshipTeamIds'>>(
                directionsInitialColumns,
                directionsCellRenderMapper,
                renderCell,
                {
                    ...i18n.getResourceBundle(i18n.language, 'pages/directions'),
                    ...i18n.getResourceBundle(i18n.language, 'common/shared'),
                }
            )}
            addItem={() => navigate('/direction')}
            updateItem={(record: Omit<DirectionModel, 'internshipTeams' | 'typeId' | 'type' | 'internshipTeamIds'>) => {
                const { id } = record;
                navigate(`/direction/${id}`);
            }}
        />
    );
};

export default Directions;
