import { gql } from '@apollo/client';

export const GET_HOMEPAGE_SETTINGS_FORM_GQL = gql`
    query {
        homepageSettings {
            byId: settings {
                introTitle
                directionsTitle
                directionsDescription
                traineeTitle
                traineeDescription
                careerStartTitle
                careerStartDescription
                updateDate
            }
        }
    }
`;

export default GET_HOMEPAGE_SETTINGS_FORM_GQL;
