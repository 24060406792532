import { useLazyQuery } from '@apollo/client';
import { Loader } from '@kl/components-v6';
import GET_AGREEMENT_GQL from 'api/queries/getAgreement';
import { FormBuilder } from 'containers';
import { FormBuilderKeys } from 'enums';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Agreement as AgreementModel } from 'types';

export const INITIAL_DATA: AgreementModel = {
    id: '',
    creationDate: new Date(),
    email: '',
    touCheckBoxIsSelected: false,
    privacyPolicyIsSelected: false,
    marketingIsSelected: false,
    marketingCheckBoxText: '',
    touCheckBoxText: '',
    touText: '',
    privacyPolicyCheckBoxText: '',
    privacyPolicyText: '',
    chapter: '',
};

const Agreement: FC = () => {
    const [agreement, setAgreement] = useState<AgreementModel>(INITIAL_DATA);
    const { t } = useTranslation(['pages/agreements', 'common/shared']);
    const navigate = useNavigate();

    const [getAgreementQuery, { loading: agreementLoading }] = useLazyQuery(GET_AGREEMENT_GQL, {
        onCompleted: (response) => {
            setAgreement(response.agreements.byId);
        },
    });

    const { id } = useParams();
    const methods = useForm<AgreementModel>();

    useEffect(() => {
        if (id) {
            getAgreementQuery({ variables: { id } });
        }
    }, []);

    return agreement ? (
        <FormProvider {...methods}>
            <FormBuilder<AgreementModel>
                data={agreement}
                formKey={FormBuilderKeys.Agreement}
                loading={agreementLoading}
                cancel={() => navigate('/courses/agreements')}
                fullWidth
            />
        </FormProvider>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default Agreement;
