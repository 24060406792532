import React, { PropsWithChildren } from 'react';
import { Button, Space } from '@kl/components-v6';
import { useFormContext } from 'react-hook-form';

interface FooterProps {
    res: {
        submitBtnText: string;
        cancelBtnText: string;
    };
    cancel: () => void;
    submit?: () => void;
    loading: boolean;
}

const Footer = (props: PropsWithChildren<FooterProps>) => {
    const {
        res: { submitBtnText, cancelBtnText },
        submit,
        cancel,
        loading = false,
    } = props;

    const {
        formState: { isDirty },
    } = useFormContext();

    return (
        <Space size={10} style={{ marginTop: 40 }}>
            {submit && (
                <Button
                    loading={loading}
                    disabled={!isDirty}
                    onClick={submit}
                    mode={'primaryBlack'}
                    text={submitBtnText}
                />
            )}

            <Button onClick={() => cancel()} mode={'secondary'} text={cancelBtnText} />
        </Space>
    );
};

export default Footer;
