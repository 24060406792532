import { gql } from '@apollo/client';

export const GET_DIRECTION_GQL = gql`
    query ($id: UUID!) {
        directions: directionsCommon {
            byId(filter: { id: $id }) {
                id
                name
                description
                number
                visible
                creationDate
                link
                typeId
                internshipTeamIds
            }
        }
    }
`;

export default GET_DIRECTION_GQL;
