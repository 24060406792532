import { gql } from '@apollo/client';

export const GET_INTERNSHIP_TEAM_GQL = gql`
    query ($id: UUID!) {
        internshipTeams {
            byId(filter: { id: $id }) {
                id
                name
                creationDate
                description
                content
                candidateRequirement
                additionalRequirement
            }
        }
    }
`;

export default GET_INTERNSHIP_TEAM_GQL;
