import { gql } from '@apollo/client';

export const GET_FA_QUESTION_GQL = gql`
    query ($id: UUID!) {
        questions {
            byId(filter: { id: $id }) {
                creationDate
                id
                question
                answer
                subject
                number
                visible
            }
        }
    }
`;

export default GET_FA_QUESTION_GQL;
