import { gql } from '@apollo/client';

export const UPDATE_HOMEPAGE_SETTINGS_FORM_GQL = gql`
    mutation ($model: HomepageSettingsViewModelInput!) {
        homepageSettings {
            addOrUpdate(model: $model) {
                introTitle
                directionsTitle
                directionsDescription
                traineeTitle
                traineeDescription
                careerStartTitle
                careerStartDescription
                updateDate
            }
        }
    }
`;

export default UPDATE_HOMEPAGE_SETTINGS_FORM_GQL;
