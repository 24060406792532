import { gql } from '@apollo/client';

export const GET_EDU_START_YEARS_GQL = gql`
    query {
        internshipYears {
            startDates {
                count
                items {
                    id
                    creationDate
                    name
                    number
                }
            }
        }
    }
`;

export default GET_EDU_START_YEARS_GQL;
