import { gql } from '@apollo/client';

export const GET_HIRING_STEPS_GQL = gql`
    query ($page: Int!, $size: Int!) {
        phaseSelectionOfIntern {
            get(filter: { page: $page, size: $size, sortByNumber: true }) {
                count
                items {
                    creationDate
                    id
                    comment
                    period
                    name
                    number
                }
            }
        }
    }
`;

export default GET_HIRING_STEPS_GQL;
