import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Heading, Loader } from '@kl/components-v6';
import UPDATE_LETTER_TPL_GQL from 'api/mutations/updateEmailTpl';
import GET_REJECTED_EMAIL_TPL_GQL from 'api/queries/getRejectedEmailTpl';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { GetFile } from 'kl-b2c-ui-kit';
import React, { FC, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RejectedEmail as RejectedEmailModel } from 'types';
import { getByteArray } from 'utils/get-byte-array';

const RejectedEmail: FC = () => {
    const [rejectedEmail, setRejectedEmail] = useState<RejectedEmailModel | null>(null);
    const { t } = useTranslation(['pages/config', 'common/shared']);
    const { setToaster } = useToaster();
    const { loading } = useQuery(GET_REJECTED_EMAIL_TPL_GQL, {
        onCompleted: (response) => {
            setRejectedEmail(response.internshipEmailTemplate.rejectedTemplate);
        },
    });
    const [emailMutation, { loading: mutationLoading }] = useMutation(UPDATE_LETTER_TPL_GQL);

    const methods = useForm<RejectedEmailModel>();

    const onSubmit = async (model: FieldValues) => {
        try {
            const bytes = await getByteArray(model.image as File);
            const image = model.image as File;
            await emailMutation({
                variables: {
                    model: {
                        ...model,
                        id: rejectedEmail?.id,
                        image: (model.image as GetFile).id
                            ? null
                            : { contentType: image.type, data: bytes, fileName: image.name },
                        triggers: [],
                    },
                },
            });
            setToaster({
                type: 'success',
                message: t('rejected-email-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return loading || !rejectedEmail ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('rejected-email')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<RejectedEmailModel>
                    data={rejectedEmail}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.RejectedEmail}
                    loading={mutationLoading}
                />
            </FormProvider>
        </>
    );
};

export default RejectedEmail;
