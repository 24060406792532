import { useLazyQuery, useMutation } from '@apollo/client';
import DELETE_EDU_GQL from 'api/mutations/deleteEduEndDate';
import DELETE_EDU_START_GQL from 'api/mutations/deleteEduStartDate';
import GET_EDU_FINISH_YEARS_GQL from 'api/queries/getEduEndYears';
import GET_EDU_START_YEARS_GQL from 'api/queries/getEduStartYears';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Years as YearsModel } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { cellRenderMapper, initialColumns } from './mappers';

interface YearsProps {
    type: 'finish' | 'start';
}

const Years: FC<YearsProps> = ({ type }) => {
    const [years, setYears] = useState<PaginationOutput<YearsModel>>({ count: 0, items: null });
    const navigate = useNavigate();

    const [getYearsQuery] = useLazyQuery(type === 'start' ? GET_EDU_START_YEARS_GQL : GET_EDU_FINISH_YEARS_GQL, {
        onCompleted: (response) => {
            const {
                internshipYears: { startDates, finishDates },
            } = response;
            setYears(type === 'start' ? startDates : finishDates);
        },
    });

    const [deleteDatesMutation] = useMutation(type === 'start' ? DELETE_EDU_START_GQL : DELETE_EDU_GQL, {
        refetchQueries: [type === 'start' ? GET_EDU_START_YEARS_GQL : GET_EDU_FINISH_YEARS_GQL],
    });

    const getYears = async (model: PaginationInput) => {
        await getYearsQuery({ variables: { ...model } });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteDatesMutation({ variables: { id } });
    };

    return (
        <PageBuilder<YearsModel>
            pageKey={type === 'start' ? PageBuilderKey.StartDates : PageBuilderKey.FinishDates}
            data={years}
            getItems={(params) => getYears(params as PaginationInput)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<YearsModel>(initialColumns, cellRenderMapper, renderCell, {
                ...i18n.getResourceBundle(i18n.language, 'pages/years'),
                ...i18n.getResourceBundle(i18n.language, 'common/shared'),
            })}
            addItem={() => navigate(type === 'start' ? '/add-start-dates' : '/add-finish-dates')}
            updateItem={(record: YearsModel) => {
                const { id } = record;
                navigate(`${type === 'start' ? '/add-start-dates/' : '/add-finish-dates/'}${id}`);
            }}
        />
    );
};

export default Years;
