import { gql } from '@apollo/client';

export const GET_INTERNSHIP_REGISTRATION_GQL = gql`
    query ($id: UUID!) {
        internshipRegistrations {
            byId(filter: { id: $id }) {
                id
                creationDate
                agreementId
                stepicCourseId
                stepicId
                firstName
                lastName
                email
                phone
                telegramNickName
                city
                university
                faculty
                startEducation
                finishEducation
                descriptionYourSelf
                whatYouWantToKnowAboutInternship
                knowAboutInternshipCity
                registrationCode
                directions {
                    id
                    name
                }
                directionsNames
                englishLevel
                hoursPerWeek
                utmTerm
                utmMedium
                utmCampaign
                utmSource
                utmContent
            }
        }
    }
`;

export default GET_INTERNSHIP_REGISTRATION_GQL;
