import { gql } from '@apollo/client';

export const ADD_DIRECTION_TYPE_GQL = gql`
    mutation ($model: AddDirectionTypeViewModelInput!) {
        directionType {
            add(model: $model) {
                id
                creationDate
                name
            }
        }
    }
`;

export default ADD_DIRECTION_TYPE_GQL;
