import { FAQ } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const faqRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Omit<FAQ, 'id' | 'creationDate'>, { type: ControlRendererType; rules?: RegisterOptions }> => ({
    question: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    answer: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 1000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 1000,
                }),
            },
        },
    },
    subject: {
        type: ControlRendererType.Hidden,
    },
    number: {
        type: ControlRendererType.Number,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
        },
    },
    visible: {
        type: ControlRendererType.CheckBox,
    },
});
