import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    height: 100%;
  }
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 25px 0;
    gap: 20px;
    position: relative;

    & > span {
        width: 150px;
        flex-shrink: 0;
    }
`;

export const Text = styled.span`
    display: -webkit-box;
    max-width: 180px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
