import React, { FC, PropsWithChildren } from 'react';
import { Icon, Button } from '@kl/components-v6';
import { UserInfoContainer } from './styled';
import { useAuth } from 'contexts/auth.context';
import { UserInfo } from 'types';

interface SiderUserInfoItemProps {
    collapsed: boolean;
    info: UserInfo;
}

const SiderUserInfo: FC<PropsWithChildren<SiderUserInfoItemProps>> = ({ collapsed, info }) => {
    const { signOut } = useAuth();

    if (!info) return null;

    return (
        <UserInfoContainer collapsed={collapsed}>
            <Button
                onClick={signOut}
                size={'large'}
                iconAfter={
                    // @ts-ignore
                    <Icon key={'signOutIcon'} size={'medium'} name={'SignOut'} themedColor={'primary-invert'} />
                }
                mode="primaryBlack"
                text={info.fullName}
            />
        </UserInfoContainer>
    );
};

export default SiderUserInfo;
