import { gql } from '@apollo/client';

export const GET_EDU_START_YEAR_GQL = gql`
    query ($id: UUID!) {
        internshipYears {
            startDateById(filter: { id: $id }) {
                id
                name
                number
            }
        }
    }
`;

export default GET_EDU_START_YEAR_GQL;
