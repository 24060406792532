import React, { Key, ReactNode, useEffect, useState } from 'react';
import { Button, Heading, Loader, Pagination, Table, Space } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { PageBuilderFilterType } from 'types';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import { Filters } from './components';
import { ModalType, PageBuilderAdditionalFilters, PageBuilderKey, FilterType } from 'enums';
import { useModal, useToaster } from 'contexts';
import { useParams } from 'react-router-dom';
import { getFilterMapper } from './mappers';

interface PageBuilderProps<TData extends { id: string }> {
    data: PaginationOutput<TData>;
    getItems: <TFilterParams>(params: TFilterParams) => Promise<void>;
    getExcel?: <TFilterParams>(params: Omit<TFilterParams, 'page' | 'size'>) => Promise<void>;
    pageKey: PageBuilderKey;
    columns: { key: string; dataIndex: string; title: string }[];
    additionalFilters?: {
        withEmpty?: boolean;
        type: PageBuilderAdditionalFilters;
        key: FilterType;
        defaultValue?: string;
        defaultValueFetchAsync?: () => Promise<string | undefined>;
        items?: {
            value: string | null;
            label: string;
        }[];
    }[];
    scroll?: {
        x?: number | string;
        y?: number | string;
    };
    deleteItem?: (id: Key) => Promise<void>;
    addItem?: () => void;
    updateItem?: (record: TData) => void;
    showItem?: (record: TData) => void;
}

const PageBuilder = <TData extends { id: string }>(props: PageBuilderProps<TData>) => {
    const {
        data,
        getItems,
        deleteItem,
        getExcel,
        pageKey,
        additionalFilters,
        columns,
        addItem,
        updateItem,
        showItem,
        scroll,
    } = props;
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<PageBuilderFilterType>(getFilterMapper(pageKey));
    const { setModal } = useModal();
    const { id } = useParams();
    const { setToaster } = useToaster();

    const { t } = useTranslation(['pages/page-builder', 'common/shared']);

    const setFilter = (newFilter: Record<string, string | number | undefined | null>) =>
        setFilters({
            ...filters,
            ...newFilter,
        });

    const downloadExcel = async () => {
        if (getExcel) {
            await getExcel({
                ...filters,
            });
        }
    };

    const getAdditionalButtons = (
        length: number
    ): { title: string; dataIndex: string; key: string; width: string }[] => {
        if (length < 1) {
            return [];
        }

        let buttons: {
            title: string;
            dataIndex: string;
            key: string;
            width: string;
            render: (_: unknown, record: TData) => ReactNode;
        }[] = [];

        if (deleteItem) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    width: '5%',
                    render: (_: unknown, record: TData): ReactNode => (
                        <Button
                            mode="dangerFilled"
                            onClick={async () => {
                                const modalContent = <div>{t('deleteOne', { type: t(pageKey).toLowerCase() })}</div>;

                                setModal(ModalType.Confirm, modalContent, async () => {
                                    try {
                                        setTableLoading(true);

                                        const { id } = record;
                                        await deleteItem(id);

                                        setToaster({
                                            type: 'success',
                                            message: t('successDelete'),
                                        });
                                    } catch (e: unknown) {
                                        if (e instanceof Error) {
                                            setToaster({
                                                type: 'error',
                                                message: e?.message,
                                            });
                                        } else {
                                            setToaster({
                                                type: 'error',
                                                message: t('somethingWrong', { ns: 'common/shared' }),
                                            });
                                        }
                                    } finally {
                                        setTableLoading(false);
                                    }
                                });
                            }}
                        >
                            {t('delete', { ns: 'common/shared' })}
                        </Button>
                    ),
                },
            ];
        }

        if (updateItem) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'update',
                    key: 'update',
                    width: '5%',
                    render: (_: unknown, record: TData) => (
                        <Button mode={'primaryBlue'} onClick={() => updateItem(record)}>
                            {t('update', { ns: 'common/shared' })}
                        </Button>
                    ),
                },
                ...buttons,
            ];
        }

        if (showItem) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'show',
                    key: 'show',
                    width: '5%',
                    render: (_: unknown, record: TData) => {
                        const button = (
                            <Button mode={'secondary'} onClick={() => showItem(record)}>
                                {t('show', { ns: 'common/shared' })}
                            </Button>
                        );
                        return button;
                    },
                },
                ...buttons,
            ];
        }

        return buttons;
    };

    useEffect(() => {
        setTableLoading(true);
        getItems(filters)
            .catch((e) => {
                setToaster({
                    type: 'error',
                    message: e.message,
                });
            })
            .finally(() => setTableLoading(false));
    }, [filters]);

    return data?.items !== null ? (
        <>
            <Heading style={{ marginBottom: 30 }} type={'H2'}>
                {t(pageKey)}
            </Heading>

            {addItem && (
                <Space direction="horizontal" size={15} style={{ marginBottom: 30 }}>
                    <Button mode={'danger'} onClick={addItem}>
                        {t('add', { ns: 'common/shared' })}
                    </Button>
                </Space>
            )}

            {additionalFilters?.length && (
                <Filters
                    setFilter={setFilter}
                    downloadExcel={getExcel ? downloadExcel : undefined}
                    disableExcelDownload={!data?.items.length}
                    additionalFilters={additionalFilters}
                    filters={filters}
                />
            )}

            <Table
                loading={tableLoading}
                style={{ marginBottom: 20 }}
                locale={{ emptyText: t('emptyTable', { title: pageKey.toLowerCase() }) }}
                toolbar={{ showFilter: true }}
                pagination={false}
                dataSource={
                    data?.items.map((item: TData) => ({
                        ...item,
                        key: item.id,
                    })) || []
                }
                scroll={{ ...scroll, scrollToFirstRowOnChange: true }}
                columns={[...columns, ...getAdditionalButtons(data?.items.length)]}
            />

            {!id && (
                <Pagination
                    onChange={(page, size) => {
                        setFilters((prevState: PageBuilderFilterType) => ({ ...prevState, page: page - 1, size }));
                    }}
                    onShowSizeChange={(page, size) => {
                        setFilters((prevState: PageBuilderFilterType) => ({
                            ...prevState,
                            page: page === 0 ? 0 : page - 1,
                            size,
                        }));
                    }}
                    total={data?.count || 0}
                    current={filters.page + 1}
                    pageSize={filters.size}
                />
            )}
        </>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default PageBuilder;
