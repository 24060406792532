import { gql } from '@apollo/client';

export const GET_INTERNSHIP_TEAMS_GQL = gql`
    query ($page: Int!, $size: Int!) {
        internshipTeams {
            get(filter: { page: $page, size: $size }) {
                count
                items {
                    id
                    name
                    creationDate
                    description
                    content
                    candidateRequirement
                    additionalRequirement
                }
            }
        }
    }
`;

export default GET_INTERNSHIP_TEAMS_GQL;
